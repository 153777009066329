import React from 'react';

const Footer = () => {
  return (
    <footer className="app-footer">
      <span className="d-inline-block">Copyright Lean4-0.be &copy; 2020 - </span>
      <span className="d-inline-block"><a href='/setup.exe'>Télécharger l'application</a></span>
    </footer>
  );
}
  ;

export default Footer;

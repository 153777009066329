import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux';
import CircularProgress from "@material-ui/core/CircularProgress";
import {Redirect} from "react-router-dom";
import { isAuthenticateTokenValid } from 'actions/Auth';

const Authenticate = (props) => {

    const dispatch = useDispatch();
    const [loaded, setLoaded] = useState(false)
    const { authUser, redirectfromauth, isValidToken, isSessionTimedOut } = useSelector(({auth}) => auth);

    useEffect(() => {
        if (!loaded) {
            let search = props.history.location.search;
            let params = new URLSearchParams(search);
            let usertoken = params.get('token');
            setLoaded(true);
            dispatch(isAuthenticateTokenValid(usertoken));
        }
    }, [dispatch, loaded, props, redirectfromauth]);

    if (!redirectfromauth && !isSessionTimedOut) {
        return (
            <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
                <div className="app-logo-content d-flex align-items-center justify-content-center">
                <Link className="logo-lg" to="/" title="Lean 4.0 Andon">
                    <img src={require("assets/images/pentagon_1.png")} alt="Lean 4.0 Andon" title="Lean 4.0 Andon"/>
                </Link>
                </div>                
                <div className="loader-view">
                    <CircularProgress/>
                </div>
            </div>
        );
    }
    else {
        if (isValidToken) {
            if (authUser.isManager || authUser.isOfficer)
                return (<Redirect to={'/app/dashboard/activitylist'}/>);
            else
                return (<Redirect to={'/app/structure/units'}/>);
        } else {
            return (<Redirect to={'/'}/>);
        }
    }
};

export default Authenticate;

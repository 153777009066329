// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'toggle_collapse_menu';
export const DRAWER_TYPE = 'drawer_type';
export const FIXED_DRAWER = 'fixed_drawer';
export const COLLAPSED_DRAWER = 'collapsible';
export const MINI_DRAWER = 'mini_drawer';
export const THEME_COLOR = 'theme_color';
export const DARK_THEME = 'dark_theme';
export const WINDOW_WIDTH = 'window-width';
export const SWITCH_LANGUAGE = 'switch-language';
export const CHANGE_DIRECTION = 'change-direction';

export const CHANGE_NAVIGATION_STYLE = 'change-navigation-style';
export const HORIZONTAL_NAVIGATION = 'horizontal_navigation';
export const VERTICAL_NAVIGATION = 'vertical_navigation';

export const HORIZONTAL_MENU_POSITION = 'horizontal_menu_position';
export const ABOVE_THE_HEADER = 'above_the_header';
export const INSIDE_THE_HEADER = 'inside_the_header';
export const BELOW_THE_HEADER = 'below_the_header';

//Contact Module const

export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';

export const FETCH_ALL_CONTACT = 'fetch_all_contact';
export const FETCH_ALL_CONTACT_SUCCESS = 'fetch_all_contact_success';
export const ADD_FAVOURITE = 'add_favourite';
export const ON_CONTACT_SELECT = 'on_contact_select';
export const ON_ADD_CONTACT = 'on_add_contact';
export const ON_CONTACT_CLOSE = 'on_contact_close';
export const ON_FILTER_OPTION_SELECT = 'on_filter_option_select';
export const ON_SAVE_CONTACT = 'on_save_contact';
export const ON_DELETE_CONTACT = 'on_delete_contact';
export const ON_DELETE_SELECTED_CONTACT = 'on_delete_selected_contact';
export const FILTER_CONTACT = 'filter_contact';
export const GET_ALL_CONTACT = 'get_all_contact';
export const GET_UNSELECTED_ALL_CONTACT = 'get_unselected_all_contact';
export const ON_ALL_CONTACT_SELECT = 'on_all_contact_select';
export const UPDATE_SEARCH_USER = 'update_search_user';
export const ON_TOGGLE_DRAWER = 'on_toggle_drawer';
export const HANDLE_REQUEST_CLOSE = 'handle_request_close';
export const HIDE_CONTACT_LOADER = 'hide_contact_loader';

//Auth const
export const SIGNUP_USER = 'signup_user';
export const SIGNUP_USER_SUCCESS = 'signup_user_success';
export const SIGNIN_GOOGLE_USER = 'signin_google_user';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'signin_google_user_success';
export const SIGNIN_FACEBOOK_USER = 'signin_facebook_user';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'signin_facebook_user_success';
export const SIGNIN_TWITTER_USER = 'signin_twitter_user';
export const SIGNIN_TWITTER_USER_SUCCESS = 'signin_twitter_user_success';
export const SIGNIN_GITHUB_USER = 'signin_github_user';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_SUCCESS';
export const SIGNIN_USER = 'signin_user';
export const SIGNIN_USER_SUCCESS = 'signin_user_success';
export const SIGNOUT_USER = 'signout_user';
export const SIGNOUT_USER_SUCCESS = 'signout_user_success';
export const INIT_URL = 'init_url';
export const USER_DATA = 'user_data';
export const USER_TOKEN_SET = 'user_token_set';
export const AUTHENTICATE_REDIRECT = 'authenticate_redirect';
export const AUTHENTICATE_REDIRECTED = 'authenticate_redirected';
export const SESSION_TIMED_OUT = 'session-timed-out';
export const SIGNIN_USER_ERROR = 'signin_user_error';

// Chat Module const
export const FETCH_ALL_CHAT_USER = 'fetch_all_chat_user';
export const FETCH_ALL_CHAT_USER_CONVERSATION = 'fetch_all_chat_user_conversation';
export const FETCH_ALL_CHAT_USER_SUCCESS = 'fetch_all_chat_user_success';
export const FETCH_ALL_CHAT_USER_CONVERSATION_SUCCESS = 'fetch_all_chat_user_conversation_success';
export const FILTER_USERS = 'filter_users';
export const ON_SELECT_USER = 'on_select_user';
export const ON_SHOW_LOADER = 'on_show_loader';
export const ON_HIDE_LOADER = 'on_hide_loader';
export const USER_INFO_STATE = 'user_info_state';
export const SUBMIT_COMMENT = 'submit_comment';
export const UPDATE_MESSAGE_VALUE = 'update_message_value';
export const UPDATE_SEARCH_CHAT_USER = 'update_search_chat_user';

//// Mail Module const
export const ADD_LABEL = 'ADD_LABEL';
export const GET_ALL_MAIL = 'get_all_mail';
export const FETCH_ALL_MAIL = 'fetch_all_mail';
export const FETCH_ALL_MAIL_SUCCESS = 'fetch_all_mail_success';
export const GET_IMPORTANT_MAIL = 'get_important_mail';
export const GET_NAV_FILTERS = 'get_nav_filters';
export const GET_NAV_LABELS = 'get_nav_labels';
export const GET_NAV_FOLDER = 'get_nav_folder';
export const GET_READ_MAIL = 'get_read_mail';
export const GET_STARRED_MAIL = 'get_starred_mail';
export const GET_UNIMPORTANT_MAIL = 'get_unimportant_mail';
export const GET_UNREAD_MAIL = 'get_unread_mail';
export const GET_UNSELECTED_ALL_MAIL = 'get_unselected_all_mail';
export const GET_UNSTARRED_MAIL = 'get_unstarred_mail';
export const ON_ALL_MAIL_SELECT = 'on_all_mail_select';
export const ON_FOLDER_MENU_ITEM_SELECT = 'on_folder_menu_item_select';
export const ON_FOLDER_SELECT = 'on_folder_select';
export const ON_IMPORTANT_SELECT = 'on_important_select';
export const ON_LABEL_MENU_ITEM_SELECT = 'on_label_menu_item_select';
export const GET_MARK_AS_START = 'get_mark_as_start';
export const ON_LABEL_SELECT = 'on_label_select';
export const ON_MAIL_CHECKED = 'on_mail_checked';
export const ON_MAIL_SELECT = 'on_mail_select';
export const ON_MAIL_SEND = 'on_mail_send';
export const ON_OPTION_MENU_ITEM_SELECT = 'on_option_menu_item_select';
export const ON_OPTION_MENU_SELECT = 'on_option_menu_select';
export const ON_START_SELECT = 'on_start_select';
export const SEARCH_MAIL = 'search_mail';
export const ON_DELETE_MAIL = 'on_delete_mail';
export const SET_CURRENT_MAIL_NULL = 'set_current_mail_null';
export const ON_COMPOSE_MAIL = 'on_compose_mail';

//// TO-DO Module const
export const ON_SORTEND = 'on_sortend';
export const FETCH_ALL_TODO = 'fetch_all_todo';
export const FETCH_ALL_TODO_SUCCESS = 'fetch_all_todo-success';
export const FETCH_ALL_TODO_CONVERSATION = 'fetch_all_todo_conversation';
export const FETCH_ALL_TODO_CONVERSATION_SUCCESS = 'fetch_all_todo_conversation_success';
export const SELECT_ALL_TODO = 'select_all_todo';
export const GET_ALL_TODO = 'get_all_todo';
export const GET_UNSELECTED_ALL_TODO = 'get_unselected_all_todo';
export const GET_STARRED_TODO = 'get_starred_todo';
export const GET_UNSTARRED_TODO = 'get_unstarred_todo';
export const GET_IMPORTANT_TODO = 'get_important_todo';
export const GET_UNIMPORTANT_TODO = 'get_unimportant_todo';
export const ON_LABEL_UPDATE = 'on_label_update';
export const ON_TODO_UPDATE = 'on_todo_update';
export const ON_DELETE_TODO = 'on_delete_todo';
export const SEARCH_TODO = 'search_todo';
export const SHOW_TODOS = 'show_todos';
export const GET_TODO_CONVERSATION = 'get_todo_conversation';
export const ON_TODO_CHECKED = 'on_todo_checked';
export const ON_TODO_ADD = 'on_todo_add';
export const ON_TODO_SELECT = 'on_todo_select';
export const SET_CURRENT_TODO_NULL = 'set_current_todo_null';
export const REMOVE_LABEL = 'remove_label';
export const UPDATE_SEARCH = 'update_search';

export const UPDATE_DEFAULT_COMPANY = 'update_default_company';

//// COMPANIES Module const
export const FETCH_ALL_COMPANIES_START = 'fetch_all_companies-start';
export const FETCH_ALL_COMPANIES_ERROR = 'fetch_all_companies-error';
export const FETCH_ALL_COMPANIES_SUCCESS = 'fetch_all_companies-success';
export const COMPANIES_DATA = 'companies-data';

//// UNITS Module const
export const FETCH_ALL_UNITS_START = 'fetch_all_units-start';
export const FETCH_ALL_UNITS_ERROR = 'fetch_all_units-error';
export const FETCH_ALL_UNITS_SUCCESS = 'fetch_all_units-success';
export const UNIT_RECORD = 'unit-record';
export const UNITS_DATA = 'units-data';
export const FILTERED_UNITS_DATA = 'units-filtered-data';
export const UNITS_TREE_DATA = 'units-tree-data';
export const OFFICER_UNITS_DATA = 'officer-units-data';
export const UPDATE_UNIT_START = 'update_unit-start';
export const UPDATE_UNIT_SUCCESS = 'update_unit-success';
export const DELETE_UNIT_START = 'delete_unit-start';
export const DELETE_UNIT_SUCCESS = 'delete_unit-success';
export const SELECT_UNIT = 'select_unit';
export const ADD_UNIT_OFFICER_START = 'add_unit_officer-start';
export const ADD_UNIT_OFFICER_SUCCESS = 'add_unit_officer-success';

//// OFFICERS Module const
export const FETCH_ALL_OFFICERS_START = 'fetch_all_officers-start';
export const FETCH_ALL_OFFICERS_ERROR = 'fetch_all_officers-error';
export const FETCH_ALL_OFFICERS_SUCCESS = 'fetch_all_officers-success';
export const OFFICERS_DATA = 'officers-data';
export const FILTERED_OFFICERS_DATA = 'officers-filtered-data';
export const UNIT_OFFICERS_DATA = 'unit-officers-data';
export const OFFICER_RECORD = 'officer-record';
export const UPDATE_OFFICER_START = 'update_officer-start';
export const UPDATE_OFFICER_SUCCESS = 'update_officer-success';
export const DELETE_OFFICER_START = 'delete_officer-start';
export const DELETE_OFFICER_SUCCESS = 'delete_officer-success';
export const SELECT_OFFICER = 'select_officer';
export const OFFICER_AFFECTED_TASK_DATA_START ="officer-affected-task-data-start";
export const OFFICER_AFFECTED_TASK_DATA_SUCCESS ="officer-affected-task-data-success";
export const DELETE_OFFICER_TASK_START = "delete-officer-task-start";
export const DELETE_OFFICER_TASK_SUCCESS = "delete-officer-task-success";
export const CREATE_OFFICER_TASK_START = "create-officer-task-start";
export const CREATE_OFFICER_TASK_SUCCESS = "create-officer-task-success";
export const OFFICERS_AFFECTED_TASK_FOR_UNIT_DATA_START = "officers-affected-task-for-unit-data-start";
export const OFFICERS_AFFECTED_TASK_FOR_UNIT_DATA_SUCCESS = "officers-affected-task-for-unit-data-success";
export const UPDATE_OFFICER_TASKS_START = " update-officer-tasks-start";
export const UPDATE_OFFICER_TASKS_SUCCESS = "update-officer-tasks-success";


//// TASKS Module const
export const FETCH_ALL_TASKS_START = 'fetch_all_tasks-start';
export const FETCH_ALL_TASKS_ERROR = 'fetch_all_tasks-error';
export const FETCH_ALL_TASKS_SUCCESS = 'fetch_all_tasks-success';
export const TASKS_DATA = 'tasks-data';
export const FILTERED_TASKS_DATA = 'tasks-filtered-data';
export const TASK_RECORD = 'task-record';
export const UPDATE_TASK_START = 'update_task-start';
export const UPDATE_TASK_SUCCESS = 'update_task-success';
export const DELETE_TASK_START = 'delete_task-start';
export const DELETE_TASK_SUCCESS = 'delete_task-success';
export const OFFICER_AFFECTABLE_TASKS_DATA = 'officer-affectable-tasks-data';
export const TASK_OFFICERS_START = 'task-officers-start';
export const TASK_OFFICERS_SUCCESS = 'task-officers-success';

/// ISSUES module const
export const FETCH_ALL_ISSUES_START = 'fetch_all_issues-start';
export const ISSUES_DATA = 'issues-data';
export const FILTERED_ISSUES_DATA = 'filtered_issues-data'; 
export const UPDATE_ISSUE_START = 'update_issue-start'; 
export const UPDATE_ISSUE_SUCCESS = 'update_issue-success'; 
export const DELETE_ISSUE_START = 'delete_issue-start'; 
export const DELETE_ISSUE_SUCCESS = 'delete_issue-success'; 
export const SELECT_ISSUE = 'select_issue';

/// COACHING module const
export const COACHINGS_DATA = 'coaching-data';
export const FILTERED_COACHINGS_DATA = 'filtered-coaching-data';
export const UPDATE_COACHING_START = 'update-coaching-data';
export const UPDATE_COACHING_SUCCESS = 'update-coaching-success';
export const DELETE_COACHING_START = 'delete-coaching-start';
export const DELETE_COACHING_SUCCESS = 'delete-coaching-success';
export const SELECT_COACHING = 'select-coaching';
export const FETCH_ALL_COACHINGS_START = 'fetch-all-coachings-start';

//// ERRORS CONST
export const ERROR_OTHERS = 'error-others';
export const CLEAN_ERROR = 'error-clean';


//// DASHBOARD
export const FETCH_DASHBOARD_TASKS_TIMELINE_START = 'fetch-dashboard-tasks-timeline-start';
export const FETCH_DASHBOARD_TASKS_TIMELINE_SUCCESS = 'fetch-dashboard-tasks-timeline-success';
export const FETCH_DASHBOARD_TASKS_TIMELINE_DATA = 'fetch-dashboard-tasks-timeline-data';
export const FETCH_DASHBOARD_TASKS_TIMELINE_FAILED = 'fetch-dashboard-tasks-timeline-failed';

export const FETCH_DASHBOARD_TASKS_PIVOTDATA_START = 'fetch-dashboard-tasks-pivotdata-start';
export const FETCH_DASHBOARD_TASKS_PIVOTDATA_SUCCESS = 'fetch-dashboard-tasks-pivotdata-success';
export const FETCH_DASHBOARD_TASKS_PIVOTDATA_DATA = 'fetch-dashboard-tasks-pivotdata-data';
export const FETCH_DASHBOARD_TASKS_PIVOTDATA_FAILED = 'fetch-dashboard-tasks-pivotdata-failed';
export const FETCH_DASHBOARD_TASKS_PIVOTDATA_FILTEREDDATA = 'fetch-dashboard-tasks-pivotdata-filtereddata';

export const FETCH_DASHBOARD_TASKDETAILSDATA_START = 'fetch-dashboard-tasks-taskdetailsdata-start';
export const FETCH_DASHBOARD_TASKDETAILSDATA_SUCCESS = 'fetch-dashboard-tasks-taskdetailsdata-success';
export const FETCH_DASHBOARD_TASKDETAILSDATA_DATA = 'fetch-dashboard-tasks-taskdetailsdata-data';
export const FETCH_DASHBOARD_TASKDETAILSDATA_FAILED = 'fetch-dashboard-tasks-taskdetailsdata-failed';
export const FETCH_DASHBOARD_TASKDETAILSDATA_FILTEREDDATA = 'fetch-dashboard-tasks-taskdetailsdata-filtereddata';

export const FETCH_DASHBOARD_TASKDETAILSDETAILDATA_START = 'fetch-dashboard-tasks-taskdetailsdetaildata-start';
export const FETCH_DASHBOARD_TASKDETAILSDETAILDATA_SUCCESS = 'fetch-dashboard-tasks-taskdetailsdetaildata-success';
export const FETCH_DASHBOARD_TASKDETAILSDETAILDATA_DATA = 'fetch-dashboard-tasks-taskdetailsdetaildata-data';
export const FETCH_DASHBOARD_TASKDETAILSDETAILDATA_FAILED = 'fetch-dashboard-tasks-taskdetailsdetaildata-failed';
export const FETCH_DASHBOARD_TASKDETAILSDETAILDATA_FILTEREDDATA = 'fetch-dashboard-tasks-taskdetailsdetaildata-filtereddata';

export const FETCH_DASHBOARD_TASKDETAILSDETAILDATEDATA_START = 'fetch-dashboard-tasks-taskdetailsdetaildatedata-start';
export const FETCH_DASHBOARD_TASKDETAILSDETAILDATEDATA_SUCCESS = 'fetch-dashboard-tasks-taskdetailsdetaildatedata-success';
export const FETCH_DASHBOARD_TASKDETAILSDETAILDATEDATA_DATA = 'fetch-dashboard-tasks-taskdetailsdetaildatedata-data';
export const FETCH_DASHBOARD_TASKDETAILSDETAILDATEDATA_FAILED = 'fetch-dashboard-tasks-taskdetailsdetaildatedata-failed';
export const FETCH_DASHBOARD_TASKDETAILSDETAILDATEDATA_FILTEREDDATA = 'fetch-dashboard-tasks-taskdetailsdetaildatedata-filtereddata';

export const FETCH_DASHBOARD_TASKS_STANDARDIZATIONDATA_START = 'fetch-dashboard-tasks-standardizationdata-start';
export const FETCH_DASHBOARD_TASKS_STANDARDIZATIONDATA_SUCCESS = 'fetch-dashboard-tasks-standardizationdata-success';
export const FETCH_DASHBOARD_TASKS_STANDARDIZATIONDATA_DATA = 'fetch-dashboard-tasks-standardizationdata-data';
export const FETCH_DASHBOARD_TASKS_STANDARDIZATIONDATA_FAILED = 'fetch-dashboard-tasks-standardizationdata-failed';
export const FETCH_DASHBOARD_TASKS_STANDARDIZATIONDATA_FILTEREDDATA = 'fetch-dashboard-tasks-standardizationdata-filtereddata';



export const FETCH_DASHBOARD_TASKS_TABLEDATA_START = 'fetch-dashboard-tasks-tabledata-start';
export const FETCH_DASHBOARD_TASKS_TABLEDATA_SUCCESS = 'fetch-dashboard-tasks-tabledata-success';
export const FETCH_DASHBOARD_TASKS_TABLEDATA_DATA = 'fetch-dashboard-tasks-tabledata-data';
export const FETCH_DASHBOARD_TASKS_TABLEDATA_FAILED = 'fetch-dashboard-tasks-tabledata-failed';
export const FETCH_DASHBOARD_TASKS_TABLEDATA_FILTEREDDATA = 'fetch-dashboard-tasks-tabledata-filtereddata';

export const FETCH_DASHBOARD_TASKSOVERMAX_TABLEDATA_START = 'fetch-dashboard-tasksovermax-tabledata-start';
export const FETCH_DASHBOARD_TASKSOVERMAX_TABLEDATA_SUCCESS = 'fetch-dashboard-tasksovermax-tabledata-success';
export const FETCH_DASHBOARD_TASKSOVERMAX_TABLEDATA_DATA = 'fetch-dashboard-tasksovermax-tabledata-data';
export const FETCH_DASHBOARD_TASKSOVERMAX_TABLEDATA_FAILED = 'fetch-dashboard-tasksovermax-tabledata-failed';
export const FETCH_DASHBOARD_TASKSOVERMAX_TABLEDATA_FILTEREDDATA = 'fetch-dashboard-tasksovermax-tabledata-filtereddata';

export const FETCH_DASHBOARD_TASKSANDON_TABLEDATA_START = 'fetch-dashboard-tasksandon-tabledata-start';
export const FETCH_DASHBOARD_TASKSANDON_TABLEDATA_SUCCESS = 'fetch-dashboard-tasksandon-tabledata-success';
export const FETCH_DASHBOARD_TASKSANDON_TABLEDATA_DATA = 'fetch-dashboard-tasksandon-tabledata-data';
export const FETCH_DASHBOARD_TASKSANDON_TABLEDATA_FAILED = 'fetch-dashboard-tasksandon-tabledata-failed';
export const FETCH_DASHBOARD_TASKSANDON_TABLEDATA_FILTEREDDATA = 'fetch-dashboard-tasksandon-tabledata-filtereddata';

export const FETCH_DASHBOARD_TASKSHELP_TABLEDATA_START = 'fetch-dashboard-taskshelp-tabledata-start';
export const FETCH_DASHBOARD_TASKSHELP_TABLEDATA_SUCCESS = 'fetch-dashboard-taskshelp-tabledata-success';
export const FETCH_DASHBOARD_TASKSHELP_TABLEDATA_DATA = 'fetch-dashboard-taskshelp-tabledata-data';
export const FETCH_DASHBOARD_TASKSHELP_TABLEDATA_FAILED = 'fetch-dashboard-taskshelp-tabledata-failed';
export const FETCH_DASHBOARD_TASKSHELP_TABLEDATA_FILTEREDDATA = 'fetch-dashboard-taskshelp-tabledata-filtereddata';

export const FETCH_DASHBOARD_AVERAGE_PERFORMANCEDATA_START = 'fetch-dashboard-average-performancedata-start';
export const FETCH_DASHBOARD_AVERAGE_PERFORMANCEDATA_SUCCESS = 'fetch-dashboard-average-performancedata-success';
export const FETCH_DASHBOARD_AVERAGE_PERFORMANCEDATA_DATA = 'fetch-dashboard-average-performancedata-data';
export const FETCH_DASHBOARD_AVERAGE_PERFORMANCEDATA_FAILED = 'fetch-dashboard-average-performancedata-failed';

export const SELECT_OFFICERTASK = 'select-officertask';
export const OFFICERTASK_RECORD = 'officertask-record';
export const UPDATE_OFFICERTASK_START = 'update_officertask-start';
export const UPDATE_OFFICERTASK_SUCCESS = 'update_officertask-success';
export const DELETE_OFFICERTASK_START = 'delete_officertask-start';
export const DELETE_OFFICERTASK_SUCCESS = 'delete_officertask-success';


export const FETCH_DASHBOARD_OFFICERTASK_TIMELINE_START = 'fetch-dashboard-officertask-timeline-start';
export const FETCH_DASHBOARD_OFFICERTASK_TIMELINE_SUCCESS = 'fetch-dashboard-officertask-timeline-success';
export const FETCH_DASHBOARD_OFFICERTASK_TIMELINE_DATA = 'fetch-dashboard-officertask-timeline-data';
export const FETCH_DASHBOARD_OFFICERTASK_TIMELINE_FAILED = 'fetch-dashboard-officertask-timeline-failed';

export const FETCH_DASHBOARD_EFFICIENCY_START = 'fetch-dashboard-efficiency-start';
export const FETCH_DASHBOARD_EFFICIENCY_SUCCESS = 'fetch-dashboard-efficiency-success';
export const FETCH_DASHBOARD_EFFICIENCY_DATA = 'fetch-dashboard-efficiency-data';
export const FETCH_DASHBOARD_EFFICIENCY_FAILED = 'fetch-dashboard-efficiency-failed';

export const FETCH_ATTENDANCES_START = 'fetch-attendances-start';
export const FETCH_ATTENDANCES_SUCCESS = 'fetch-attendances-success';
export const FETCH_ATTENDANCES_DATA = 'fetch-attendances-data';
export const FETCH_ATTENDANCES_FILTEREDDATA = 'fetch-attendances-filtereddata';
export const FETCH_ATTENDANCES_FAILED = 'fetch-attendances-failed';
export const SELECT_ATTENDANCE = 'select-attendance';
export const ATTENDANCE_RECORD = 'attendance-record';
export const UPDATE_ATTENDANCE_START = 'update_attendance-start';
export const UPDATE_ATTENDANCE_SUCCESS = 'update_attendance-success';
export const DELETE_ATTENDANCE_START = 'delete_attendance-start';
export const DELETE_ATTENDANCE_SUCCESS = 'delete_attendance-success';

export const FETCH_DASHBOARD_TASKTIMEPERIOD_START = 'fetch-dashboard-tasktimeperiod-start';
export const FETCH_DASHBOARD_TASKTIMEPERIOD_SUCCESS = 'fetch-dashboard-tasktimeperiod-success';
export const FETCH_DASHBOARD_TASKTIMEPERIOD_DATA = 'fetch-dashboard-tasktimeperiod-data';
export const FETCH_DASHBOARD_TASKTIMEPERIOD_FAILED = 'fetch-dashboard-tasktimeperiod-failed';

export const FETCH_DASHBOARD_TASKTOTALTIMEPERIOD_START = 'fetch-dashboard-tasktotaltimeperiod-start';
export const FETCH_DASHBOARD_TASKTOTALTIMEPERIOD_SUCCESS = 'fetch-dashboard-tasktotaltimeperiod-success';
export const FETCH_DASHBOARD_TASKTOTALTIMEPERIOD_DATA = 'fetch-dashboard-tasktotaltimeperiod-data';
export const FETCH_DASHBOARD_TASKTOTALTIMEPERIOD_FAILED = 'fetch-dashboard-tasktotaltimeperiod-failed';

export const FETCH_DASHBOARD_TASKCOUNTPERIOD_START = 'fetch-dashboard-taskcountperiod-start';
export const FETCH_DASHBOARD_TASKCOUNTPERIOD_SUCCESS = 'fetch-dashboard-taskcountperiod-success';
export const FETCH_DASHBOARD_TASKCOUNTPERIOD_DATA = 'fetch-dashboard-taskcountperiod-data';
export const FETCH_DASHBOARD_TASKCOUNTPERIOD_FAILED = 'fetch-dashboard-taskcountperiod-failed';

export const FETCH_DASHBOARD_ISSUESTIMEPERIOD_START = 'fetch-dashboard-issuestimeperiod-start';
export const FETCH_DASHBOARD_ISSUESTIMEPERIOD_SUCCESS = 'fetch-dashboard-issuestimeperiod-success';
export const FETCH_DASHBOARD_ISSUESTIMEPERIOD_DATA = 'fetch-dashboard-issuestimeperiod-data';
export const FETCH_DASHBOARD_ISSUESTIMEPERIOD_FAILED = 'fetch-dashboard-issuestimeperiod-failed';

export const FETCH_DASHBOARD_ISSUESCOUNTPERIOD_START = 'fetch-dashboard-issuescountperiod-start';
export const FETCH_DASHBOARD_ISSUESCOUNTPERIOD_SUCCESS = 'fetch-dashboard-issuescountperiod-success';
export const FETCH_DASHBOARD_ISSUESCOUNTPERIOD_DATA = 'fetch-dashboard-issuescountperiod-data';
export const FETCH_DASHBOARD_ISSUESCOUNTPERIOD_FAILED = 'fetch-dashboard-issuescountperiod-failed';

export const FETCH_DASHBOARD_COACHINGSTIMEPERIOD_START = 'fetch-dashboard-coachingstimeperiod-start';
export const FETCH_DASHBOARD_COACHINGSTIMEPERIOD_SUCCESS= 'fetch-dashboard-coachingstimeperiod-success';
export const FETCH_DASHBOARD_COACHINGSTIMEPERIOD_DATA = 'fetch-dashboard-coachingstimeperiod-data';
export const FETCH_DASHBOARD_COACHINGSTIMEPERIOD_FAILED = 'fetch-dashboard-coachingstimeperiod-failed';

export const FETCH_DASHBOARD_COACHINGSCOUNTPERIOD_START = 'fetch-dashboard-coachingscountperiod-start';
export const FETCH_DASHBOARD_COACHINGSCOUNTPERIOD_SUCCESS = 'fetch-dashboard-coachingscountperiod-success';
export const FETCH_DASHBOARD_COACHINGSCOUNTPERIOD_DATA = 'fetch-dashboard-coachingscountperiod-data';
export const FETCH_DASHBOARD_COACHINGSCOUNTPERIOD_FAILED = 'fetch-dashboard-coachingscountperiod-failed';

export const FETCH_DASHBOARD_TEAMEFFICIENCY_START = 'fetch-dashboard-teamefficiency-start';
export const FETCH_DASHBOARD_TEAMEFFICIENCY_SUCCESS = 'fetch-dashboard-teamefficiency-success';
export const FETCH_DASHBOARD_TEAMEFFICIENCY_DATA = 'fetch-dashboard-teamefficiency-data';
export const FETCH_DASHBOARD_TEAMEFFICIENCY_FAILED = 'fetch-dashboard-teamefficiency-failed';

export const FETCH_DASHBOARD_TEAMEFFICIENCYOFFICERS_START = 'fetch-dashboard-teamefficiencyofficers-start';
export const FETCH_DASHBOARD_TEAMEFFICIENCYOFFICERS_SUCCESS = 'fetch-dashboard-teamefficiencyofficers-success';
export const FETCH_DASHBOARD_TEAMEFFICIENCYOFFICERS_DATA = 'fetch-dashboard-teamefficiencyofficers-data';
export const FETCH_DASHBOARD_TEAMEFFICIENCYOFFICERS_FAILED = 'fetch-dashboard-teamefficiencyofficers-failed';

export const FETCH_DASHBOARD_DISTRIBUTION_START = 'fetch-dashboard-distribution-start';
export const FETCH_DASHBOARD_DISTRIBUTION_SUCCESS = 'fetch-dashboard-distribution-success';
export const FETCH_DASHBOARD_DISTRIBUTION_DATA = 'fetch-dashboard-distribution-data';
export const FETCH_DASHBOARD_DISTRIBUTION_FAILED = 'fetch-dashboard-distribution-failed';
export const FETCH_DASHBOARD_DISTRIBUTION_ANDON_START = 'fetch-dashboard-distribution-andon-start';
export const FETCH_DASHBOARD_DISTRIBUTION_ANDON_SUCCESS = 'fetch-dashboard-distribution-andon-success';
export const FETCH_DASHBOARD_DISTRIBUTION_ANDON_DATA = 'fetch-dashboard-distribution-andon-data';
export const FETCH_DASHBOARD_DISTRIBUTION_ANDON_FAILED = 'fetch-dashboard-distribution-andon-failed';
export const FETCH_DASHBOARD_DISTRIBUTION_HELP_START = 'fetch-dashboard-distribution-help-start';
export const FETCH_DASHBOARD_DISTRIBUTION_HELP_SUCCESS = 'fetch-dashboard-distribution-help-success';
export const FETCH_DASHBOARD_DISTRIBUTION_HELP_DATA = 'fetch-dashboard-distribution-help-data';
export const FETCH_DASHBOARD_DISTRIBUTION_HELP_FAILED = 'fetch-dashboard-distribution-help-failed';
export const FETCH_DASHBOARD_DISTRIBUTION_COACHING_START = 'fetch-dashboard-distribution-coaching-start';
export const FETCH_DASHBOARD_DISTRIBUTION_COACHING_SUCCESS = 'fetch-dashboard-distribution-coaching-success';
export const FETCH_DASHBOARD_DISTRIBUTION_COACHING_DATA = 'fetch-dashboard-distribution-coaching-data';
export const FETCH_DASHBOARD_DISTRIBUTION_COACHING_FAILED = 'fetch-dashboard-distribution-coaching-failed';

export const FETCH_DASHBOARD_ISSUES_START = 'fetch-dashboard-issues-start';
export const FETCH_DASHBOARD_ISSUES_SUCCESS = 'fetch-dashboard-issues-success';
export const FETCH_DASHBOARD_ISSUES_DATA = 'fetch-dashboard-issues-data';
export const FETCH_DASHBOARD_ISSUES_FAILED = 'fetch-dashboard-issues-failed';
export const FETCH_DASHBOARD_ISSUES_REAL_TIME_DATA = 'fetch-dashboard-issues-realtime-data';

export const FETCH_DASHBOARD_COACHINGS_START = 'fetch-dashboard-coachings-start';
export const FETCH_DASHBOARD_COACHINGS_SUCCESS = 'fetch-dashboard-coachings-success';
export const FETCH_DASHBOARD_COACHINGS_DATA = 'fetch-dashboard-coachings-data';
export const FETCH_DASHBOARD_COACHINGS_FAILED = 'fetch-dashboard-coachings-failed';
export const FETCH_DASHBOARD_COACHINGS_REAL_TIME_DATA = 'fetch-dashboard-coachings-realtime-data';

export const FETCH_DASHBOARD_TASKS_START = 'fetch-dashboard-tasks-start';
export const FETCH_DASHBOARD_TASKS_SUCCESS = 'fetch-dashboard-tasks-success';
export const FETCH_DASHBOARD_TASKS_FAILED = 'fetch-dashboard-tasks-failed';
export const FETCH_DASHBOARD_TASKS_REAL_TIME_DATA = 'fetch-dashboard-tasks-realtime-data';
export const FETCH_DASHBOARD_TASKS_DATA = 'fetch-dashboard-tasks-data';
export const FETCH_DASHBOARD_TASKS_FILTEREDDATA = 'fetch-dashboard-tasks-filtereddata';

export const FETCH_DASHBOARD_DISTRIBUTION_COACHINGS_START = 'fetch-dashboard-distribution-coachings-start';
export const FETCH_DASHBOARD_DISTRIBUTION_COACHINGS_SUCCESS = 'fetch-dashboard-distribution-coachings-success';
export const FETCH_DASHBOARD_DISTRIBUTION_COACHINGS_DATA = 'fetch-dashboard-distribution-coachings-data';
export const FETCH_DASHBOARD_DISTRIBUTION_COACHINGS_FAILED = 'fetch-dashboard-distribution-coachings-failed';
export const FETCH_DASHBOARD_DISTRIBUTION_COACHINGS_REAL_TIME_DATA = 'fetch-dashboard-distribution-coachings-realtime-data';

export const FETCH_DASHBOARD_DISTRIBUTION_ISSUES_START = 'fetch-dashboard-distribution-issues-start';
export const FETCH_DASHBOARD_DISTRIBUTION_ISSUES_SUCCESS = 'fetch-dashboard-distribution-issues-success';
export const FETCH_DASHBOARD_DISTRIBUTION_ISSUES_DATA = 'fetch-dashboard-distribution-issues-data';
export const FETCH_DASHBOARD_DISTRIBUTION_ISSUES_FAILED = 'fetch-dashboard-distribution-issues-failed';
export const FETCH_DASHBOARD_DISTRIBUTION_ISSUES_REAL_TIME_DATA = 'fetch-dashboard-issues-distribution-realtime-data';


export const DASHBOARD_FILTERS_SELECTEDCOMPANY = 'dashboard-filters-selectedcompany';
export const DASHBOARD_FILTERS_SELECTEDUNIT = 'dashboard-filters-selectedunit';
export const DASHBOARD_FILTERS_SELECTEDOFFICER = 'dashboard-filters-selectedofficer';
export const DASHBOARD_FILTERS_SELECTEDTASK = 'dashboard-filters-selectedtask';
export const DASHBOARD_FILTERS_SELECTEDDATEFROM = 'dashboard-filters-selecteddatefrom';
export const DASHBOARD_FILTERS_SELECTEDDATETO = 'dashboard-filters-selecteddateto';
export const DASHBOARD_FILTERS_SHOWVALUE = 'dashboard-filters-showvalue';
export const DASHBOARD_FILTERS_SELECTEDVIEWTYPE = 'dashboard-filters-selectedviewtype';
export const DASHBOARD_FILTERS_SELECTEDISSUE = 'dashboard-filters-selectedissue';
export const DASHBOARD_FILTERS_SELECTEDCOACHING = 'dashboard-filters-selectedCoaching';
export const DASHBOARD_FILTERS_CLEAR_SELECTEDCOMPANY = 'dashboard-filters-clear-selectedcompany';
export const DASHBOARD_FILTERS_CLEAR_SELECTEDUNIT = 'dashboard-filters-clear-selectedunit';
export const DASHBOARD_FILTERS_CLEAR_SELECTEDOFFICER = 'dashboard-filters-clear-selectedofficer';
export const DASHBOARD_FILTERS_CLEAR_SELECTEDTASK = 'dashboard-filters-clear-selectedtask';
export const DASHBOARD_FILTERS_SELECTEDLIMITTYPE = "dashboard-filters-selected-limittype";
export const DASHBOARD_FILTERS_SHOW_ANDON = "dashboard-filters-selected-showAndon";

export const FETCH_PIVOTTABLEREPORTSDATA_START = 'fetch-pivottablereportsdata-start';
export const FETCH_PIVOTTABLEREPORTSDATA_SUCCESS = 'fetch-pivottablereportsdata-success';
export const FETCH_PIVOTTABLEREPORTSDATA_DATA = 'fetch-pivottablereportsdata-data';
export const FETCH_PIVOTTABLEREPORTSDATA_FAILED = 'fetch-pivottablereportsdata-failed';
export const SELECT_PIVOTTABLEREPORTS = 'select-pivottablereports';
export const PIVOTTABLEREPORTS_RECORD = 'pivottablereports-record';
export const UPDATE_PIVOTTABLEREPORTS_START = 'update-pivottablereports-start';
export const UPDATE_PIVOTTABLEREPORTS_SUCCESS = 'update-pivottablereports-success';

export const FETCH_DASHBOARD_TASKSLOGS_TABLEDATA_START = 'fetch-dashboard-taskslogs-tabledata-start';
export const FETCH_DASHBOARD_TASKSLOGS_TABLEDATA_SUCCESS = 'fetch-dashboard-taskslogs-tabledata-success';
export const FETCH_DASHBOARD_TASKSLOGS_TABLEDATA_DATA = 'fetch-dashboard-taskslogs-tabledata-data';
export const FETCH_DASHBOARD_TASKSLOGS_TABLEDATA_FAILED = 'fetch-dashboard-taskslogs-tabledata-failed';
export const FETCH_DASHBOARD_TASKSLOGS_TABLEDATA_FILTEREDDATA = 'fetch-dashboard-taskslogs-tabledata-filtereddata';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import asyncComponent from "../../../util/asyncComponent";

const Settings = ({ match }) => (
    <div className="app-wrapper">
        <Switch>
            <Redirect exact from={`${match.url}/`} to={`${match.url}/Units`} />
            <Route exact path={`${match.url}/units`} component={asyncComponent(() => import('./routes/Units'))} />
            <Route exact path={`${match.url}/attendances`} component={asyncComponent(() => import('./routes/Attendances'))} />
            <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
        </Switch>
    </div>
);

export default Settings;
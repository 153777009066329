import { 
    FETCH_DASHBOARD_TASKS_TIMELINE_START, 
    FETCH_DASHBOARD_TASKS_TIMELINE_DATA,
    FETCH_DASHBOARD_TASKS_TIMELINE_SUCCESS, 
    FETCH_DASHBOARD_TASKS_TIMELINE_FAILED,
    FETCH_DASHBOARD_TASKS_PIVOTDATA_START, 
    FETCH_DASHBOARD_TASKS_PIVOTDATA_DATA,
    FETCH_DASHBOARD_TASKS_PIVOTDATA_SUCCESS, 
    FETCH_DASHBOARD_TASKS_PIVOTDATA_FAILED,
    FETCH_DASHBOARD_TASKS_PIVOTDATA_FILTEREDDATA,
    FETCH_DASHBOARD_TASKDETAILSDATA_START, 
    FETCH_DASHBOARD_TASKDETAILSDATA_DATA,
    FETCH_DASHBOARD_TASKDETAILSDATA_SUCCESS, 
    FETCH_DASHBOARD_TASKDETAILSDATA_FAILED,
    FETCH_DASHBOARD_TASKDETAILSDATA_FILTEREDDATA,
    FETCH_DASHBOARD_TASKDETAILSDETAILDATA_START, 
    FETCH_DASHBOARD_TASKDETAILSDETAILDATA_DATA,
    FETCH_DASHBOARD_TASKDETAILSDETAILDATA_SUCCESS, 
    FETCH_DASHBOARD_TASKDETAILSDETAILDATA_FAILED,
    FETCH_DASHBOARD_TASKDETAILSDETAILDATA_FILTEREDDATA,
    FETCH_DASHBOARD_TASKDETAILSDETAILDATEDATA_START, 
    FETCH_DASHBOARD_TASKDETAILSDETAILDATEDATA_DATA,
    FETCH_DASHBOARD_TASKDETAILSDETAILDATEDATA_SUCCESS, 
    FETCH_DASHBOARD_TASKDETAILSDETAILDATEDATA_FAILED,
    FETCH_DASHBOARD_TASKDETAILSDETAILDATEDATA_FILTEREDDATA,
    FETCH_DASHBOARD_AVERAGE_PERFORMANCEDATA_START, 
    FETCH_DASHBOARD_AVERAGE_PERFORMANCEDATA_DATA,
    FETCH_DASHBOARD_AVERAGE_PERFORMANCEDATA_SUCCESS, 
    FETCH_DASHBOARD_AVERAGE_PERFORMANCEDATA_FAILED,
    FETCH_DASHBOARD_TASKS_STANDARDIZATIONDATA_START,
    FETCH_DASHBOARD_TASKS_STANDARDIZATIONDATA_SUCCESS,
    FETCH_DASHBOARD_TASKS_STANDARDIZATIONDATA_DATA,
    FETCH_DASHBOARD_TASKS_STANDARDIZATIONDATA_FAILED,
    FETCH_DASHBOARD_TASKS_STANDARDIZATIONDATA_FILTEREDDATA,
    FETCH_DASHBOARD_TASKS_TABLEDATA_FAILED,
    FETCH_DASHBOARD_TASKS_TABLEDATA_START, 
    FETCH_DASHBOARD_TASKS_TABLEDATA_DATA,
    FETCH_DASHBOARD_TASKS_TABLEDATA_FILTEREDDATA,
    FETCH_DASHBOARD_TASKS_TABLEDATA_SUCCESS, 
    FETCH_DASHBOARD_TASKSOVERMAX_TABLEDATA_FAILED,
    FETCH_DASHBOARD_TASKSOVERMAX_TABLEDATA_START, 
    FETCH_DASHBOARD_TASKSOVERMAX_TABLEDATA_DATA,
    FETCH_DASHBOARD_TASKSOVERMAX_TABLEDATA_FILTEREDDATA,
    FETCH_DASHBOARD_TASKSOVERMAX_TABLEDATA_SUCCESS,
    FETCH_DASHBOARD_TASKSANDON_TABLEDATA_FAILED,
    FETCH_DASHBOARD_TASKSANDON_TABLEDATA_START, 
    FETCH_DASHBOARD_TASKSANDON_TABLEDATA_DATA,
    FETCH_DASHBOARD_TASKSANDON_TABLEDATA_FILTEREDDATA,
    FETCH_DASHBOARD_TASKSANDON_TABLEDATA_SUCCESS,    
    FETCH_DASHBOARD_TASKSHELP_TABLEDATA_FAILED,
    FETCH_DASHBOARD_TASKSHELP_TABLEDATA_START, 
    FETCH_DASHBOARD_TASKSHELP_TABLEDATA_DATA,
    FETCH_DASHBOARD_TASKSHELP_TABLEDATA_FILTEREDDATA,
    FETCH_DASHBOARD_TASKSHELP_TABLEDATA_SUCCESS,
    SELECT_OFFICERTASK,
    OFFICERTASK_RECORD,
    UPDATE_OFFICERTASK_START,
    UPDATE_OFFICERTASK_SUCCESS,
    DELETE_OFFICERTASK_START,
    DELETE_OFFICERTASK_SUCCESS,
    FETCH_DASHBOARD_OFFICERTASK_TIMELINE_START,
    FETCH_DASHBOARD_OFFICERTASK_TIMELINE_SUCCESS,
    FETCH_DASHBOARD_OFFICERTASK_TIMELINE_DATA,
    FETCH_DASHBOARD_OFFICERTASK_TIMELINE_FAILED,
    FETCH_DASHBOARD_EFFICIENCY_START,
    FETCH_DASHBOARD_EFFICIENCY_SUCCESS,
    FETCH_DASHBOARD_EFFICIENCY_DATA,
    FETCH_DASHBOARD_EFFICIENCY_FAILED,
    FETCH_DASHBOARD_TASKTIMEPERIOD_START,
    FETCH_DASHBOARD_TASKTIMEPERIOD_SUCCESS,
    FETCH_DASHBOARD_TASKTIMEPERIOD_DATA,
    FETCH_DASHBOARD_TASKTIMEPERIOD_FAILED,
    FETCH_DASHBOARD_TASKTOTALTIMEPERIOD_START,
    FETCH_DASHBOARD_TASKTOTALTIMEPERIOD_SUCCESS,
    FETCH_DASHBOARD_TASKTOTALTIMEPERIOD_DATA,
    FETCH_DASHBOARD_TASKTOTALTIMEPERIOD_FAILED,
    FETCH_DASHBOARD_TASKCOUNTPERIOD_START,
    FETCH_DASHBOARD_TASKCOUNTPERIOD_SUCCESS,
    FETCH_DASHBOARD_TASKCOUNTPERIOD_DATA,
    FETCH_DASHBOARD_TASKCOUNTPERIOD_FAILED,
    FETCH_DASHBOARD_ISSUESTIMEPERIOD_START,
    FETCH_DASHBOARD_ISSUESTIMEPERIOD_SUCCESS,
    FETCH_DASHBOARD_ISSUESTIMEPERIOD_DATA,
    FETCH_DASHBOARD_ISSUESTIMEPERIOD_FAILED,
    FETCH_DASHBOARD_ISSUESCOUNTPERIOD_START,
    FETCH_DASHBOARD_ISSUESCOUNTPERIOD_SUCCESS,
    FETCH_DASHBOARD_ISSUESCOUNTPERIOD_DATA,
    FETCH_DASHBOARD_ISSUESCOUNTPERIOD_FAILED,
    FETCH_DASHBOARD_COACHINGSTIMEPERIOD_START,
    FETCH_DASHBOARD_COACHINGSTIMEPERIOD_SUCCESS,
    FETCH_DASHBOARD_COACHINGSTIMEPERIOD_DATA,
    FETCH_DASHBOARD_COACHINGSTIMEPERIOD_FAILED,
    FETCH_DASHBOARD_COACHINGSCOUNTPERIOD_START,
    FETCH_DASHBOARD_COACHINGSCOUNTPERIOD_SUCCESS,
    FETCH_DASHBOARD_COACHINGSCOUNTPERIOD_DATA,
    FETCH_DASHBOARD_COACHINGSCOUNTPERIOD_FAILED,
    FETCH_DASHBOARD_TEAMEFFICIENCY_START,
    FETCH_DASHBOARD_TEAMEFFICIENCY_SUCCESS,
    FETCH_DASHBOARD_TEAMEFFICIENCY_DATA,
    FETCH_DASHBOARD_TEAMEFFICIENCY_FAILED,
    FETCH_DASHBOARD_TEAMEFFICIENCYOFFICERS_START,
    FETCH_DASHBOARD_TEAMEFFICIENCYOFFICERS_SUCCESS,
    FETCH_DASHBOARD_TEAMEFFICIENCYOFFICERS_DATA,
    FETCH_DASHBOARD_TEAMEFFICIENCYOFFICERS_FAILED,
    FETCH_DASHBOARD_DISTRIBUTION_START,
    FETCH_DASHBOARD_DISTRIBUTION_SUCCESS,
    FETCH_DASHBOARD_DISTRIBUTION_DATA,
    FETCH_DASHBOARD_DISTRIBUTION_FAILED,
    FETCH_DASHBOARD_DISTRIBUTION_ANDON_START,
    FETCH_DASHBOARD_DISTRIBUTION_ANDON_SUCCESS,
    FETCH_DASHBOARD_DISTRIBUTION_ANDON_DATA,
    FETCH_DASHBOARD_DISTRIBUTION_ANDON_FAILED,
    FETCH_DASHBOARD_DISTRIBUTION_HELP_START,
    FETCH_DASHBOARD_DISTRIBUTION_HELP_SUCCESS,
    FETCH_DASHBOARD_DISTRIBUTION_HELP_DATA,
    FETCH_DASHBOARD_DISTRIBUTION_HELP_FAILED,
    FETCH_DASHBOARD_DISTRIBUTION_COACHINGS_START,
    FETCH_DASHBOARD_DISTRIBUTION_COACHINGS_SUCCESS,
    FETCH_DASHBOARD_DISTRIBUTION_COACHINGS_DATA,
    FETCH_DASHBOARD_DISTRIBUTION_COACHINGS_FAILED,
    FETCH_DASHBOARD_DISTRIBUTION_ISSUES_START,
    FETCH_DASHBOARD_DISTRIBUTION_ISSUES_SUCCESS,
    FETCH_DASHBOARD_DISTRIBUTION_ISSUES_DATA,
    FETCH_DASHBOARD_DISTRIBUTION_ISSUES_FAILED,
    FETCH_DASHBOARD_ISSUES_START,
    FETCH_DASHBOARD_ISSUES_SUCCESS,
    FETCH_DASHBOARD_ISSUES_DATA,
    FETCH_DASHBOARD_ISSUES_REAL_TIME_DATA,
    FETCH_DASHBOARD_ISSUES_FAILED,
    FETCH_DASHBOARD_COACHINGS_START,
    FETCH_DASHBOARD_COACHINGS_SUCCESS,
    FETCH_DASHBOARD_COACHINGS_DATA,
    FETCH_DASHBOARD_COACHINGS_FAILED,
    FETCH_DASHBOARD_COACHINGS_REAL_TIME_DATA,
    FETCH_DASHBOARD_TASKS_START,
    FETCH_DASHBOARD_TASKS_SUCCESS,
    FETCH_DASHBOARD_TASKS_FAILED,
    FETCH_DASHBOARD_TASKS_REAL_TIME_DATA,
    DASHBOARD_FILTERS_SELECTEDCOMPANY,
    DASHBOARD_FILTERS_CLEAR_SELECTEDCOMPANY,
    DASHBOARD_FILTERS_SELECTEDUNIT,
    DASHBOARD_FILTERS_SELECTEDOFFICER,
    DASHBOARD_FILTERS_SELECTEDTASK,
    DASHBOARD_FILTERS_SELECTEDDATEFROM,
    DASHBOARD_FILTERS_SELECTEDDATETO,
    DASHBOARD_FILTERS_SHOWVALUE,
    DASHBOARD_FILTERS_SELECTEDVIEWTYPE,
    DASHBOARD_FILTERS_SELECTEDISSUE,
    DASHBOARD_FILTERS_SELECTEDCOACHING,
    DASHBOARD_FILTERS_SELECTEDLIMITTYPE,
    DASHBOARD_FILTERS_SHOW_ANDON,
    SIGNOUT_USER_SUCCESS,
    ERROR_OTHERS,
    FETCH_ERROR,
    FETCH_DASHBOARD_TASKSLOGS_TABLEDATA_FAILED,
    FETCH_DASHBOARD_TASKSLOGS_TABLEDATA_START, 
    FETCH_DASHBOARD_TASKSLOGS_TABLEDATA_DATA,
    FETCH_DASHBOARD_TASKSLOGS_TABLEDATA_FILTEREDDATA,
    FETCH_DASHBOARD_TASKSLOGS_TABLEDATA_SUCCESS,
} from "../constants/ActionTypes";
import axios from 'util/Api';

export const fetchTasksTimeLine = (timeLineFilter) => {
    return (dispatch) => {
        dispatch({ type: FETCH_DASHBOARD_TASKS_TIMELINE_START });
        axios.post('/taskstimeline', timeLineFilter)
        .then(({ data }) => {
            if (data.result) {
                dispatch({ type: FETCH_DASHBOARD_TASKS_TIMELINE_SUCCESS });
                dispatch({ type: FETCH_DASHBOARD_TASKS_TIMELINE_DATA, payload: data.result.timeLines });
            } else {
                console.log("payload: data.error", data.error);
                dispatch({ type: FETCH_DASHBOARD_TASKS_TIMELINE_FAILED, payload: "Network Error" });
            }
        }).catch(function (error) {
            if (error.response.status === 401) {
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                dispatch({type: SIGNOUT_USER_SUCCESS});
            } else {
                dispatch({ type: ERROR_OTHERS, payload: error });
            }                  
            dispatch({ type: FETCH_DASHBOARD_TASKS_TIMELINE_FAILED, payload: error.message });
            console.log("Error****:", error.message);
        });
    }
};

export const fetchTasksPivotData = (pivotDataFilter) => {
    return (dispatch) => {
        dispatch({ type: FETCH_DASHBOARD_TASKS_PIVOTDATA_START });
        axios.post('/taskspivotdata', pivotDataFilter)
        .then(({ data }) => {
            if (data.result) {
                dispatch({ type: FETCH_DASHBOARD_TASKS_PIVOTDATA_SUCCESS });
                dispatch({ type: FETCH_DASHBOARD_TASKS_PIVOTDATA_DATA, payload: data.result.pivotData });
            } else {
                console.log("payload: data.error", data.error);
                dispatch({ type: FETCH_DASHBOARD_TASKS_PIVOTDATA_FAILED, payload: "Network Error" });
            }
        }).catch(function (error) {
            if (error.response.status === 401) {
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                dispatch({type: SIGNOUT_USER_SUCCESS});
            } else {
                dispatch({ type: ERROR_OTHERS, payload: error });
            }                  
            dispatch({ type: FETCH_DASHBOARD_TASKS_PIVOTDATA_FAILED, payload: error.message });
            console.log("Error****:", error.message);
        });
    }
};

export const filterPivotData = (data, filterText) => {
    return (dispatch) => {
        var filteredData = data.filter(function(text) {
                if (filterText.length > 0) {
                    return (
                            (text['Cellule'] === null ? false : text['Cellule'].toLowerCase().indexOf(filterText.toLowerCase()) >= 0) || 
                            (text['Tâche'] === null ? false : (text['Tâche'].toLowerCase().indexOf(filterText.toLowerCase()) >= 0)) ||
                            (text['Officer'] === null ? false : (text['Officer'].toLowerCase().indexOf(filterText.toLowerCase()) >= 0))
                    );
                } else {
                    return true;
                }
        });
        dispatch({ type: FETCH_DASHBOARD_TASKS_PIVOTDATA_FILTEREDDATA, payload: filteredData });
    }
};

export const fetchTasksTableData = (tableDataFilter) => {
    return (dispatch) => {
        dispatch({ type: FETCH_DASHBOARD_TASKS_TABLEDATA_START });
        axios.post('/taskstabledata', tableDataFilter)
        .then(({ data }) => {
            if (data.result) {
                dispatch({ type: FETCH_DASHBOARD_TASKS_TABLEDATA_SUCCESS });
                dispatch({ type: FETCH_DASHBOARD_TASKS_TABLEDATA_DATA, payload: data.result.tableData });
            } else {
                console.log("payload: data.error", data.error);
                dispatch({ type: FETCH_DASHBOARD_TASKS_TABLEDATA_FAILED, payload: "Network Error" });
            }
        }).catch(function (error) {
            if (error.response.status === 401) {
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                dispatch({type: SIGNOUT_USER_SUCCESS});
            } else {
                dispatch({ type: ERROR_OTHERS, payload: error });
            }                  
            dispatch({ type: FETCH_DASHBOARD_TASKS_TABLEDATA_FAILED, payload: error.message });
            console.log("Error****:", error.message);
        });
    }
};

export const filterTableData = (data, filterText) => {
    return (dispatch) => {
        var filteredData = data.filter(function(text) {
                if (filterText.length > 0) {
                    return (
                            (text.companyName.toLowerCase().indexOf(filterText.toLowerCase()) >= 0) || 
                            (text.unitName === null ? false : (text.unitName.toLowerCase().indexOf(filterText.toLowerCase()) >= 0)) ||
                            (text.officer === null ? false : (text.officer.toLowerCase().indexOf(filterText.toLowerCase()) >= 0)) ||
                            (text.taskName === null ? false : (text.taskName.toLowerCase().indexOf(filterText.toLowerCase()) >= 0))
                    );
                } else {
                    return true;
                }
        });
        dispatch({ type: FETCH_DASHBOARD_TASKS_TABLEDATA_FILTEREDDATA, payload: filteredData });
    }
};

export const fetchTasksOverMaxTableData = (tableDataFilter) => {
    return (dispatch) => {
        dispatch({ type: FETCH_DASHBOARD_TASKSOVERMAX_TABLEDATA_START });
        axios.post('/tasksovermaxtabledata', tableDataFilter)
        .then(({ data }) => {
            if (data.result) {
                dispatch({ type: FETCH_DASHBOARD_TASKSOVERMAX_TABLEDATA_SUCCESS });
                dispatch({ type: FETCH_DASHBOARD_TASKSOVERMAX_TABLEDATA_DATA, payload: data.result.tableData });
            } else {
                console.log("payload: data.error", data.error);
                dispatch({ type: FETCH_DASHBOARD_TASKSOVERMAX_TABLEDATA_FAILED, payload: "Network Error" });
            }
        }).catch(function (error) {
            if (error.response.status === 401) {
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                dispatch({type: SIGNOUT_USER_SUCCESS});
            } else {
                dispatch({ type: ERROR_OTHERS, payload: error });
            }                  
            dispatch({ type: FETCH_DASHBOARD_TASKSOVERMAX_TABLEDATA_FAILED, payload: error.message });
            console.log("Error****:", error.message);
        });
    }
};

export const filterOverMaxTableData = (data, filterText) => {
    return (dispatch) => {
        var filteredData = data.filter(function(text) {
                if (filterText.length > 0) {
                    return (
                            (text.companyName.toLowerCase().indexOf(filterText.toLowerCase()) >= 0) || 
                            (text.unitName === null ? false : (text.unitName.toLowerCase().indexOf(filterText.toLowerCase()) >= 0)) ||
                            (text.officer === null ? false : (text.officer.toLowerCase().indexOf(filterText.toLowerCase()) >= 0)) ||
                            (text.taskName === null ? false : (text.taskName.toLowerCase().indexOf(filterText.toLowerCase()) >= 0))
                    );
                } else {
                    return true;
                }
        });
        dispatch({ type: FETCH_DASHBOARD_TASKSOVERMAX_TABLEDATA_FILTEREDDATA, payload: filteredData });
    }
};

export const fetchTasksAndonTableData = (tableDataFilter) => {
    return (dispatch) => {
        dispatch({ type: FETCH_DASHBOARD_TASKSANDON_TABLEDATA_START });
        axios.post('/tasksandontabledata', tableDataFilter)
        .then(({ data }) => {
            if (data.result) {
                dispatch({ type: FETCH_DASHBOARD_TASKSANDON_TABLEDATA_SUCCESS });
                dispatch({ type: FETCH_DASHBOARD_TASKSANDON_TABLEDATA_DATA, payload: data.result.tableData });
            } else {
                console.log("payload: data.error", data.error);
                dispatch({ type: FETCH_DASHBOARD_TASKSANDON_TABLEDATA_FAILED, payload: "Network Error" });
            }
        }).catch(function (error) {
            if (error.response.status === 401) {
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                dispatch({type: SIGNOUT_USER_SUCCESS});
            } else {
                dispatch({ type: ERROR_OTHERS, payload: error });
            }                  
            dispatch({ type: FETCH_DASHBOARD_TASKSANDON_TABLEDATA_FAILED, payload: error.message });
            console.log("Error****:", error.message);
        });
    }
};

export const filterAndonTableData = (data, filterText) => {
    return (dispatch) => {
        var filteredData = data.filter(function(text) {
                if (filterText.length > 0) {
                    return (
                            (text.companyName.toLowerCase().indexOf(filterText.toLowerCase()) >= 0) || 
                            (text.unitName === null ? false : (text.unitName.toLowerCase().indexOf(filterText.toLowerCase()) >= 0)) ||
                            (text.officer === null ? false : (text.officer.toLowerCase().indexOf(filterText.toLowerCase()) >= 0)) ||
                            (text.taskName === null ? false : (text.taskName.toLowerCase().indexOf(filterText.toLowerCase()) >= 0))
                    );
                } else {
                    return true;
                }
        });
        dispatch({ type: FETCH_DASHBOARD_TASKSANDON_TABLEDATA_FILTEREDDATA, payload: filteredData });
    }
};

export const fetchTasksHelpTableData = (tableDataFilter) => {
    return (dispatch) => {
        dispatch({ type: FETCH_DASHBOARD_TASKSHELP_TABLEDATA_START });
        axios.post('/taskshelptabledata', tableDataFilter)
        .then(({ data }) => {
            if (data.result) {
                dispatch({ type: FETCH_DASHBOARD_TASKSHELP_TABLEDATA_SUCCESS });
                dispatch({ type: FETCH_DASHBOARD_TASKSHELP_TABLEDATA_DATA, payload: data.result.tableData });
            } else {
                console.log("payload: data.error", data.error);
                dispatch({ type: FETCH_DASHBOARD_TASKSHELP_TABLEDATA_FAILED, payload: "Network Error" });
            }
        }).catch(function (error) {
            if (error.response.status === 401) {
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                dispatch({type: SIGNOUT_USER_SUCCESS});
            } else {
                dispatch({ type: ERROR_OTHERS, payload: error });
            }                  
            dispatch({ type: FETCH_DASHBOARD_TASKSHELP_TABLEDATA_FAILED, payload: error.message });
            console.log("Error****:", error.message);
        });
    }
};

export const filterTasksHelpTableData = (data, filterText) => {
    return (dispatch) => {
        var filteredData = data.filter(function(text) {
                if (filterText.length > 0) {
                    return (
                            (text.companyName.toLowerCase().indexOf(filterText.toLowerCase()) >= 0) || 
                            (text.unitName === null ? false : (text.unitName.toLowerCase().indexOf(filterText.toLowerCase()) >= 0)) ||
                            (text.officer === null ? false : (text.officer.toLowerCase().indexOf(filterText.toLowerCase()) >= 0)) ||
                            (text.taskName === null ? false : (text.taskName.toLowerCase().indexOf(filterText.toLowerCase()) >= 0))
                    );
                } else {
                    return true;
                }
        });
        dispatch({ type: FETCH_DASHBOARD_TASKSHELP_TABLEDATA_FILTEREDDATA, payload: filteredData });
    }
};

export const sortData = (data, order, orderBy) => {
    return (dispatch) => {
        order === "desc"
            ? data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
            : data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));
        dispatch({ type: FETCH_DASHBOARD_TASKS_PIVOTDATA_DATA, payload: data });
    }
};

export const selectOfficerTask = (selectOfficerTask) => {
    return (dispatch) => {
        dispatch({ type: SELECT_OFFICERTASK, payload: selectOfficerTask });
    }
}

export const createOrUpdateOfficerTask = (officerTask, refreshSelected) => {
    return (dispatch) => {
        dispatch({ type: UPDATE_OFFICERTASK_START });
        var retValue = null;

        axios.post('/officertask', {
            ...officerTask
        })
            .then(({ data }) => {
                if (data.result) {
                    dispatch({ type: UPDATE_OFFICERTASK_SUCCESS });
                    dispatch({ type: OFFICERTASK_RECORD, payload: data.result.officerTask });
                    retValue = data.result.officerTask;
                } else {
                    console.log("payload: data.error", data.error);
                    dispatch({ type: FETCH_ERROR, payload: "Network Error" });
                }
            })
            .then(() => {
                if (refreshSelected) {
                    dispatch({ type: SELECT_OFFICERTASK, payload: retValue });
                }
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    dispatch({type: SIGNOUT_USER_SUCCESS});
                } else {
                    dispatch({ type: ERROR_OTHERS, payload: error });
                }                  
                dispatch({ type: FETCH_ERROR, payload: error.message });
                console.log("Error****:", error.message);
            });
    }
};

export const deleteOfficerTask = (officerTask, tableDataFilter) => {
    return (dispatch) => {
        dispatch({ type: DELETE_OFFICERTASK_START });
        axios.delete('/officertask', {
            data: {...officerTask}
        })
            .then(({ data }) => {
                if (data.result) {
                    dispatch({ type: DELETE_OFFICERTASK_SUCCESS });
                    dispatch(fetchTasksTableData(tableDataFilter));
                } else {
                    console.log("payload: data.error", data.error);
                    dispatch({ type: FETCH_ERROR, payload: "Network Error" });
                }
            }).catch(function (error) {
                if (error.response.status === 401) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    dispatch({type: SIGNOUT_USER_SUCCESS});
                } else {
                    dispatch({ type: ERROR_OTHERS, payload: error });
                }                
                dispatch({ type: FETCH_ERROR, payload: error.message });
                console.log("Error****:", error.message);
            });
    }
};

export const fetchOfficerTaskTimeLine = (officerTaskId) => {
    return (dispatch) => {
        dispatch({ type: FETCH_DASHBOARD_OFFICERTASK_TIMELINE_START });
        axios.get('/getofficertasktimeline', {
            params: {
                officerTaskId: officerTaskId
            }
            })
        .then(({ data }) => {
            if (data.result) {
                dispatch({ type: FETCH_DASHBOARD_OFFICERTASK_TIMELINE_SUCCESS });
                dispatch({ type: FETCH_DASHBOARD_OFFICERTASK_TIMELINE_DATA, payload: data.result.commonTasks });
            } else {
                console.log("payload: data.error", data.error);
                dispatch({ type: FETCH_DASHBOARD_OFFICERTASK_TIMELINE_FAILED, payload: "Network Error" });
            }
        }).catch(function (error) {
            if (error.response.status === 401) {
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                dispatch({type: SIGNOUT_USER_SUCCESS});
            } else {
                dispatch({ type: ERROR_OTHERS, payload: error });
            }                  
            dispatch({ type: FETCH_DASHBOARD_OFFICERTASK_TIMELINE_FAILED, payload: error.message });
            console.log("Error****:", error.message);
        });
    }
};

export const fetchTaskDetailsData = (taskDetailsDataFilter) => {
    return (dispatch) => {
        dispatch({ type: FETCH_DASHBOARD_TASKDETAILSDATA_START });
        axios.post('/taskdetailsdata', taskDetailsDataFilter)
        .then(({ data }) => {
            if (data.result) {
                dispatch({ type: FETCH_DASHBOARD_TASKDETAILSDATA_SUCCESS });
                dispatch({ type: FETCH_DASHBOARD_TASKDETAILSDATA_DATA, payload: data.result.taskDetailsData });
            } else {
                console.log("payload: data.error", data.error);
                dispatch({ type: FETCH_DASHBOARD_TASKDETAILSDATA_FAILED, payload: "Network Error" });
            }
        }).catch(function (error) {
            console.log("Error****:", error.message);
            if (error.response.status === 401) {
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                dispatch({type: SIGNOUT_USER_SUCCESS});
            } else {
                dispatch({ type: ERROR_OTHERS, payload: error });
            }                  
            dispatch({ type: FETCH_DASHBOARD_TASKDETAILSDATA_FAILED, payload: error.message });
            console.log("Error****:", error.message);
        });
    }
};

export const filterTaskDetailsData = (data, filterText) => {
    return (dispatch) => {
        var filteredData = data.filter(function(text) {
                if (filterText.length > 0) {
                    return (
                            (text['Cellule'] === null ? false : text['Cellule'].toLowerCase().indexOf(filterText.toLowerCase()) >= 0) || 
                            (text['Tâche'] === null ? false : (text['Tâche'].toLowerCase().indexOf(filterText.toLowerCase()) >= 0)) ||
                            (text['Officer'] === null ? false : (text['Officer'].toLowerCase().indexOf(filterText.toLowerCase()) >= 0))
                    );
                } else {
                    return true;
                }
        });
        dispatch({ type: FETCH_DASHBOARD_TASKDETAILSDATA_FILTEREDDATA, payload: filteredData });
    }
};

export const fetchTaskDetailsDetailData = (taskDetailsDetailDataFilter) => {
    return (dispatch) => {
        dispatch({ type: FETCH_DASHBOARD_TASKDETAILSDETAILDATA_START });
        axios.post('/taskdetailsdetaildata', taskDetailsDetailDataFilter)
        .then(({ data }) => {
            if (data.result) {
                dispatch({ type: FETCH_DASHBOARD_TASKDETAILSDETAILDATA_SUCCESS });
                dispatch({ type: FETCH_DASHBOARD_TASKDETAILSDETAILDATA_DATA, payload: data.result.taskDetailsDetailData });
            } else {
                console.log("payload: data.error", data.error);
                dispatch({ type: FETCH_DASHBOARD_TASKDETAILSDETAILDATA_FAILED, payload: "Network Error" });
            }
        }).catch(function (error) {
            if (error.response.status === 401) {
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                dispatch({type: SIGNOUT_USER_SUCCESS});
            } else {
                dispatch({ type: ERROR_OTHERS, payload: error });
            }                  
            dispatch({ type: FETCH_DASHBOARD_TASKDETAILSDETAILDATA_FAILED, payload: error.message });
            console.log("Error****:", error.message);
        });
    }
};

export const filterTaskDetailsDetailData = (data, filterText) => {
    return (dispatch) => {
        var filteredData = data.filter(function(text) {
                if (filterText.length > 0) {
                    return (
                            (text['Cellule'] === null ? false : text['Cellule'].toLowerCase().indexOf(filterText.toLowerCase()) >= 0) || 
                            (text['Tâche'] === null ? false : (text['Tâche'].toLowerCase().indexOf(filterText.toLowerCase()) >= 0)) ||
                            (text['OfficerName'] === null ? false : (text['OfficerName'].toLowerCase().indexOf(filterText.toLowerCase()) >= 0))
                    );
                } else {
                    return true;
                }
        });
        dispatch({ type: FETCH_DASHBOARD_TASKDETAILSDETAILDATA_FILTEREDDATA, payload: filteredData });
    }
};

export const fetchTaskDetailsDetailDateData = (taskDetailsDetailDateDataFilter) => {
    return (dispatch) => {
        dispatch({ type: FETCH_DASHBOARD_TASKDETAILSDETAILDATEDATA_START });
        axios.post('/taskdetailsdetaildatedata', taskDetailsDetailDateDataFilter)
        .then(({ data }) => {
            if (data.result) {
                dispatch({ type: FETCH_DASHBOARD_TASKDETAILSDETAILDATEDATA_SUCCESS });
                dispatch({ type: FETCH_DASHBOARD_TASKDETAILSDETAILDATEDATA_DATA, payload: data.result.taskDetailsDetailDateData });
            } else {
                console.log("payload: data.error", data.error);
                dispatch({ type: FETCH_DASHBOARD_TASKDETAILSDETAILDATEDATA_FAILED, payload: "Network Error" });
            }
        }).catch(function (error) {
            if (error.response.status === 401) {
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                dispatch({type: SIGNOUT_USER_SUCCESS});
            } else {
                dispatch({ type: ERROR_OTHERS, payload: error });
            }                  
            dispatch({ type: FETCH_DASHBOARD_TASKDETAILSDETAILDATEDATA_FAILED, payload: error.message });
            console.log("Error****:", error.message);
        });
    }
};

export const filterTaskDetailsDetailDateData = (data, filterText) => {
    return (dispatch) => {
        var filteredData = data.filter(function(text) {
                if (filterText.length > 0) {
                    return (
                            (text['Cellule'] === null ? false : text['Cellule'].toLowerCase().indexOf(filterText.toLowerCase()) >= 0) || 
                            (text['Tâche'] === null ? false : (text['Tâche'].toLowerCase().indexOf(filterText.toLowerCase()) >= 0)) ||
                            (text['OfficerName'] === null ? false : (text['OfficerName'].toLowerCase().indexOf(filterText.toLowerCase()) >= 0))
                    );
                } else {
                    return true;
                }
        });
        dispatch({ type: FETCH_DASHBOARD_TASKDETAILSDETAILDATEDATA_FILTEREDDATA, payload: filteredData });
    }
};

export const fetchPerformanceAverageData = (performanceAverageDataFilter) => {
    return (dispatch) => {
        dispatch({ type: FETCH_DASHBOARD_AVERAGE_PERFORMANCEDATA_START });
        axios.post('/performanceaveragedata', performanceAverageDataFilter)
        .then(({ data }) => {
            if (data.result) {
                dispatch({ type: FETCH_DASHBOARD_AVERAGE_PERFORMANCEDATA_SUCCESS });
                dispatch({ type: FETCH_DASHBOARD_AVERAGE_PERFORMANCEDATA_DATA, payload: data.result.performanceAverageData });
            } else {
                console.log("payload: data.error", data.error);
                dispatch({ type: FETCH_DASHBOARD_AVERAGE_PERFORMANCEDATA_FAILED, payload: "Network Error" });
            }
        }).catch(function (error) {
            console.log("Error****:", error.message);
            if (error.response.status === 401) {
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                dispatch({type: SIGNOUT_USER_SUCCESS});
            } else {
                dispatch({ type: ERROR_OTHERS, payload: error });
            }                  
            dispatch({ type: FETCH_DASHBOARD_AVERAGE_PERFORMANCEDATA_FAILED, payload: error.message });
            console.log("Error****:", error.message);
        });
    }
};

export const fetchStandardizationData = (standardizationDataFilter) => {
    return (dispatch) => {
        dispatch({ type: FETCH_DASHBOARD_TASKS_STANDARDIZATIONDATA_START });
        axios.post('/standardizationdata', standardizationDataFilter)
        .then(({ data }) => {
            if (data.result) {
                dispatch({ type: FETCH_DASHBOARD_TASKS_STANDARDIZATIONDATA_SUCCESS });
                dispatch({ type: FETCH_DASHBOARD_TASKS_STANDARDIZATIONDATA_DATA, payload: data.result.standardizationData });
            } else {
                console.log("payload: data.error", data.error);
                dispatch({ type: FETCH_DASHBOARD_TASKS_STANDARDIZATIONDATA_FAILED, payload: "Network Error" });
            }
        }).catch(function (error) {
            if (error.response.status === 401) {
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                dispatch({type: SIGNOUT_USER_SUCCESS});
            } else {
                dispatch({ type: ERROR_OTHERS, payload: error });
            }                  
            dispatch({ type: FETCH_DASHBOARD_TASKS_STANDARDIZATIONDATA_FAILED, payload: error.message });
            console.log("Error****:", error.message);
        });
    }
};

export const filterStandardizationData = (data, filterText) => {
    return (dispatch) => {
        var filteredData = data.filter(function(text) {
                if (filterText.length > 0) {
                    return (
                            (text['Value'] === null ? false : text['Cellule'].toLowerCase().indexOf(filterText.toLowerCase()) >= 0)
                    );
                } else {
                    return true;
                }
        });
        dispatch({ type: FETCH_DASHBOARD_TASKS_STANDARDIZATIONDATA_FILTEREDDATA, payload: filteredData });
    }
};

export const fetchEfficiencyData = (efficiencyFilter) => {
    return (dispatch) => {
        dispatch({ type: FETCH_DASHBOARD_EFFICIENCY_START });
        axios.post('/getefficiency', efficiencyFilter)
        .then(({ data }) => {
            if (data.result) {
                dispatch({ type: FETCH_DASHBOARD_EFFICIENCY_SUCCESS });
                dispatch({ type: FETCH_DASHBOARD_EFFICIENCY_DATA, payload: data.result.efficiencyData });
            } else {
                console.log("payload: data.error", data.error);
                dispatch({ type: FETCH_DASHBOARD_EFFICIENCY_FAILED, payload: "Network Error" });
            }
        }).catch(function (error) {
            if (error.response.status === 401) {
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                dispatch({type: SIGNOUT_USER_SUCCESS});
            } else {
                dispatch({ type: ERROR_OTHERS, payload: error });
            }                  
            dispatch({ type: FETCH_DASHBOARD_EFFICIENCY_FAILED, payload: error.message });
            console.log("Error****:", error.message);
        });
    }
};

export const fetchTaskTimePeriodData = (taskTimePeriodFilter) => {
    return (dispatch) => {
        dispatch({ type: FETCH_DASHBOARD_TASKTIMEPERIOD_START });
        axios.post('/taskstimeperiod', taskTimePeriodFilter)
        .then(({ data }) => {
            if (data.result) {
                dispatch({ type: FETCH_DASHBOARD_TASKTIMEPERIOD_SUCCESS });
                dispatch({ type: FETCH_DASHBOARD_TASKTIMEPERIOD_DATA, payload: data.result.taskTimePeriodData });
            } else {
                console.log("payload: data.error", data.error);
                dispatch({ type: FETCH_DASHBOARD_TASKTIMEPERIOD_FAILED, payload: "Network Error" });
            }
        }).catch(function (error) {
            if (error.response.status === 401) {
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                dispatch({type: SIGNOUT_USER_SUCCESS});
            } else {
                dispatch({ type: ERROR_OTHERS, payload: error });
            }                  
            dispatch({ type: FETCH_DASHBOARD_TASKTIMEPERIOD_FAILED, payload: error.message });
            console.log("Error****:", error.message);
        });
    }
};

export const fetchTaskTotalTimePeriodData = (taskTotalTimePeriodFilter) => {
    return (dispatch) => {
        dispatch({ type: FETCH_DASHBOARD_TASKTOTALTIMEPERIOD_START });
        axios.post('/taskstotaltimeperiod', taskTotalTimePeriodFilter)
        .then(({ data }) => {
            if (data.result) {
                dispatch({ type: FETCH_DASHBOARD_TASKTOTALTIMEPERIOD_SUCCESS });
                dispatch({ type: FETCH_DASHBOARD_TASKTOTALTIMEPERIOD_DATA, payload: data.result.taskTotalTimePeriodData });
            } else {
                console.log("payload: data.error", data.error);
                dispatch({ type: FETCH_DASHBOARD_TASKTOTALTIMEPERIOD_FAILED, payload: "Network Error" });
            }
        }).catch(function (error) {
            if (error.response.status === 401) {
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                dispatch({type: SIGNOUT_USER_SUCCESS});
            } else {
                dispatch({ type: ERROR_OTHERS, payload: error });
            }                  
            dispatch({ type: FETCH_DASHBOARD_TASKTOTALTIMEPERIOD_FAILED, payload: error.message });
            console.log("Error****:", error.message);
        });
    }
};

export const fetchTaskCountPeriodData = (taskCountPeriodFilter) => {
    return (dispatch) => {
        dispatch({ type: FETCH_DASHBOARD_TASKCOUNTPERIOD_START });
        axios.post('/taskscountperiod', taskCountPeriodFilter)
        .then(({ data }) => {
            if (data.result) {
                dispatch({ type: FETCH_DASHBOARD_TASKCOUNTPERIOD_SUCCESS });
                dispatch({ type: FETCH_DASHBOARD_TASKCOUNTPERIOD_DATA, payload: data.result.taskCountPeriodData });
            } else {
                console.log("payload: data.error", data.error);
                dispatch({ type: FETCH_DASHBOARD_TASKCOUNTPERIOD_FAILED, payload: "Network Error" });
            }
        }).catch(function (error) {
            if (error.response.status === 401) {
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                dispatch({type: SIGNOUT_USER_SUCCESS});
            } else {
                dispatch({ type: ERROR_OTHERS, payload: error });
            }                  
            dispatch({ type: FETCH_DASHBOARD_TASKCOUNTPERIOD_FAILED, payload: error.message });
            console.log("Error****:", error.message);
        });
    }
};

export const fetchIssuesTimePeriodData = (issuesTimePeriodFilter) => {
    return (dispatch) => {
        dispatch({ type: FETCH_DASHBOARD_ISSUESTIMEPERIOD_START });
        axios.post('/issuestimeperiod', issuesTimePeriodFilter)
        .then(({ data }) => {
            if (data.result) {
                dispatch({ type: FETCH_DASHBOARD_ISSUESTIMEPERIOD_SUCCESS });
                dispatch({ type: FETCH_DASHBOARD_ISSUESTIMEPERIOD_DATA, payload: data.result.issuesTimePeriodData });
            } else {
                console.log("payload: data.error", data.error);
                dispatch({ type: FETCH_DASHBOARD_ISSUESTIMEPERIOD_FAILED, payload: "Network Error" });
            }
        }).catch(function (error) {
            if (error.response.status === 401) {
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                dispatch({type: SIGNOUT_USER_SUCCESS});
            } else {
                dispatch({ type: ERROR_OTHERS, payload: error });
            }                  
            dispatch({ type: FETCH_DASHBOARD_ISSUESTIMEPERIOD_FAILED, payload: error.message });
            console.log("Error****:", error.message);
        });
    }
};

export const fetchIssuesCountPeriodData = (issuesCountPeriodFilter) => {
    return (dispatch) => {
        dispatch({ type: FETCH_DASHBOARD_ISSUESCOUNTPERIOD_START });
        axios.post('/issuescountperiod', issuesCountPeriodFilter)
        .then(({ data }) => {
            if (data.result) {
                dispatch({ type: FETCH_DASHBOARD_ISSUESCOUNTPERIOD_SUCCESS });
                dispatch({ type: FETCH_DASHBOARD_ISSUESCOUNTPERIOD_DATA, payload: data.result.issuesCountPeriodData });
            } else {
                console.log("payload: data.error", data.error);
                dispatch({ type: FETCH_DASHBOARD_ISSUESCOUNTPERIOD_FAILED, payload: "Network Error" });
            }
        }).catch(function (error) {
            if (error.response.status === 401) {
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                dispatch({type: SIGNOUT_USER_SUCCESS});
            } else {
                dispatch({ type: ERROR_OTHERS, payload: error });
            }                  
            dispatch({ type: FETCH_DASHBOARD_ISSUESCOUNTPERIOD_FAILED, payload: error.message });
            console.log("Error****:", error.message);
        });
    }
};

export const fetchCoachingsTimePeriodData = (coachingsTimePeriodFilter) => {
    return (dispatch) => {
        dispatch({ type: FETCH_DASHBOARD_COACHINGSTIMEPERIOD_START });
        axios.post('/coachingstimeperiod', coachingsTimePeriodFilter)
        .then(({ data }) => {
            if (data.result) {
                dispatch({ type: FETCH_DASHBOARD_COACHINGSTIMEPERIOD_SUCCESS });
                dispatch({ type: FETCH_DASHBOARD_COACHINGSTIMEPERIOD_DATA, payload: data.result.coachingsTimePeriodData });
            } else {
                console.log("payload: data.error", data.error);
                dispatch({ type: FETCH_DASHBOARD_COACHINGSTIMEPERIOD_FAILED, payload: "Network Error" });
            }
        }).catch(function (error) {
            if (error.response.status === 401) {
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                dispatch({type: SIGNOUT_USER_SUCCESS});
            } else {
                dispatch({ type: ERROR_OTHERS, payload: error });
            }                  
            dispatch({ type: FETCH_DASHBOARD_COACHINGSTIMEPERIOD_FAILED, payload: error.message });
            console.log("Error****:", error.message);
        });
    }
};

export const fetchCoachingsCountPeriodData = (coachingsCountPeriodFilter) => {
    return (dispatch) => {
        dispatch({ type: FETCH_DASHBOARD_COACHINGSCOUNTPERIOD_START });
        axios.post('/coachingscountperiod', coachingsCountPeriodFilter)
        .then(({ data }) => {
            if (data.result) {
                dispatch({ type: FETCH_DASHBOARD_COACHINGSCOUNTPERIOD_SUCCESS });
                dispatch({ type: FETCH_DASHBOARD_COACHINGSCOUNTPERIOD_DATA, payload: data.result.coachingsCountPeriodData });
            } else {
                console.log("payload: data.error", data.error);
                dispatch({ type: FETCH_DASHBOARD_COACHINGSCOUNTPERIOD_FAILED, payload: "Network Error" });
            }
        }).catch(function (error) {
            if (error.response.status === 401) {
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                dispatch({type: SIGNOUT_USER_SUCCESS});
            } else {
                dispatch({ type: ERROR_OTHERS, payload: error });
            }                  
            dispatch({ type: FETCH_DASHBOARD_COACHINGSCOUNTPERIOD_FAILED, payload: error.message });
            console.log("Error****:", error.message);
        });
    }
};

export const fetchTeamEfficiencyData = (teamEfficiencyFilter) => {
    return (dispatch) => {
        dispatch({ type: FETCH_DASHBOARD_TEAMEFFICIENCY_START });

        if (teamEfficiencyFilter.unitId > 0 || teamEfficiencyFilter.officerId > 0 || teamEfficiencyFilter.companyId > 0) {
            axios.post('/getteamefficiency', teamEfficiencyFilter)
            .then(({ data }) => {
                if (data.result) {
                    dispatch({ type: FETCH_DASHBOARD_TEAMEFFICIENCY_SUCCESS });
                    dispatch({ type: FETCH_DASHBOARD_TEAMEFFICIENCY_DATA, payload: data.result.teamEfficiencyData });
                } else {
                    console.log("payload: data.error", data.error);
                    dispatch({ type: FETCH_DASHBOARD_TEAMEFFICIENCY_FAILED, payload: "Network Error" });
                }
            }).catch(function (error) {
                if (error.response.status === 401) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    dispatch({type: SIGNOUT_USER_SUCCESS});
                } else {
                    dispatch({ type: ERROR_OTHERS, payload: error });
                }                  
                dispatch({ type: FETCH_DASHBOARD_TEAMEFFICIENCY_FAILED, payload: error.message });
                console.log("Error****:", error.message);
            });
        } else {
            dispatch({ type: FETCH_DASHBOARD_TEAMEFFICIENCY_SUCCESS });
            dispatch({ type: FETCH_DASHBOARD_TEAMEFFICIENCY_DATA, payload:  { 'best': [], 'worst': [], 'unit': [], 'task': [], 'officer': [] } });            
        }


    }
};

export const fetchTeamEfficiencyOfficersData = (teamEfficiencyFilter) => {
    return (dispatch) => {
        dispatch({ type: FETCH_DASHBOARD_TEAMEFFICIENCYOFFICERS_START });

        if (teamEfficiencyFilter.unitId > 0 || teamEfficiencyFilter.officerId > 0 || teamEfficiencyFilter.companyId > 0) {
            axios.post('/getteamefficiencyofficers', teamEfficiencyFilter)
            .then(({ data }) => {
                if (data.result) {
                    dispatch({ type: FETCH_DASHBOARD_TEAMEFFICIENCYOFFICERS_SUCCESS });
                    dispatch({ type: FETCH_DASHBOARD_TEAMEFFICIENCYOFFICERS_DATA, payload: data.result.teamEfficiencyOfficerData });
                } else {
                    console.log("payload: data.error", data.error);
                    dispatch({ type: FETCH_DASHBOARD_TEAMEFFICIENCYOFFICERS_FAILED, payload: "Network Error" });
                }
            }).catch(function (error) {
                if (error.response.status === 401) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    dispatch({type: SIGNOUT_USER_SUCCESS});
                } else {
                    dispatch({ type: ERROR_OTHERS, payload: error });
                }                  
                dispatch({ type: FETCH_DASHBOARD_TEAMEFFICIENCYOFFICERS_FAILED, payload: error.message });
                console.log("Error****:", error.message);
            });
        } else {
            dispatch({ type: FETCH_DASHBOARD_TEAMEFFICIENCYOFFICERS_SUCCESS });
            dispatch({ type: FETCH_DASHBOARD_TEAMEFFICIENCYOFFICERS_DATA, payload: [] });
        }
    }
};

export const fetchDistributionData = (distributionDataFilter) => {
    return (dispatch) => {
        dispatch({ type: FETCH_DASHBOARD_DISTRIBUTION_START });

        if (distributionDataFilter.unitId > 0 || distributionDataFilter.officerId > 0 || distributionDataFilter.companyId > 0) {
            axios.post('/distributiondata', distributionDataFilter)
            .then(({ data }) => {
                if (data.result) {
                    dispatch({ type: FETCH_DASHBOARD_DISTRIBUTION_SUCCESS });
                    dispatch({ type: FETCH_DASHBOARD_DISTRIBUTION_DATA, payload: data.result.distributionData });
                } else {
                    console.log("payload: data.error", data.error);
                    dispatch({ type: FETCH_DASHBOARD_DISTRIBUTION_FAILED, payload: "Network Error" });
                }
            }).catch(function (error) {
                if (error.response.status === 401) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    dispatch({type: SIGNOUT_USER_SUCCESS});
                } else {
                    dispatch({ type: ERROR_OTHERS, payload: error });
                }                  
                dispatch({ type: FETCH_DASHBOARD_DISTRIBUTION_FAILED, payload: error.message });
                console.log("Error****:", error.message);
            });
        } else {
            dispatch({ type: FETCH_DASHBOARD_DISTRIBUTION_SUCCESS });
            dispatch({ type: FETCH_DASHBOARD_DISTRIBUTION_DATA, payload: [] });
        }
    }
};

export const fetchDistributionAndonData = (distributionAndonDataFilter) => {
    return (dispatch) => {
        dispatch({ type: FETCH_DASHBOARD_DISTRIBUTION_ANDON_START });

        if (distributionAndonDataFilter.unitId > 0 || distributionAndonDataFilter.officerId > 0 || distributionAndonDataFilter.companyId > 0) {
            axios.post('/distributionandondata', distributionAndonDataFilter)
            .then(({ data }) => {
                if (data.result) {
                    dispatch({ type: FETCH_DASHBOARD_DISTRIBUTION_ANDON_SUCCESS });
                    dispatch({ type: FETCH_DASHBOARD_DISTRIBUTION_ANDON_DATA, payload: data.result.distributionAndonData });
                } else {
                    console.log("payload: data.error", data.error);
                    dispatch({ type: FETCH_DASHBOARD_DISTRIBUTION_ANDON_FAILED, payload: "Network Error" });
                }
            }).catch(function (error) {
                if (error.response.status === 401) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    dispatch({type: SIGNOUT_USER_SUCCESS});
                } else {
                    dispatch({ type: ERROR_OTHERS, payload: error });
                }                  
                dispatch({ type: FETCH_DASHBOARD_DISTRIBUTION_ANDON_FAILED, payload: error.message });
                console.log("Error****:", error.message);
            });
        } else {
            dispatch({ type: FETCH_DASHBOARD_DISTRIBUTION_ANDON_SUCCESS });
            dispatch({ type: FETCH_DASHBOARD_DISTRIBUTION_ANDON_DATA, payload: [] });
        }
    }
};

export const fetchDistributionHelpData = (distributionHelpDataFilter) => {
    return (dispatch) => {
        dispatch({ type: FETCH_DASHBOARD_DISTRIBUTION_HELP_START });

        if (distributionHelpDataFilter.unitId > 0 || distributionHelpDataFilter.officerId > 0 || distributionHelpDataFilter.companyId > 0) {
            axios.post('/distributionhelpdata', distributionHelpDataFilter)
            .then(({ data }) => {
                if (data.result) {
                    dispatch({ type: FETCH_DASHBOARD_DISTRIBUTION_HELP_SUCCESS });
                    dispatch({ type: FETCH_DASHBOARD_DISTRIBUTION_HELP_DATA, payload: data.result.distributionHelpData });
                } else {
                    console.log("payload: data.error", data.error);
                    dispatch({ type: FETCH_DASHBOARD_DISTRIBUTION_HELP_FAILED, payload: "Network Error" });
                }
            }).catch(function (error) {
                if (error.response.status === 401) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    dispatch({type: SIGNOUT_USER_SUCCESS});
                } else {
                    dispatch({ type: ERROR_OTHERS, payload: error });
                }                  
                dispatch({ type: FETCH_DASHBOARD_DISTRIBUTION_HELP_FAILED, payload: error.message });
                console.log("Error****:", error.message);
            });
        } else {
            dispatch({ type: FETCH_DASHBOARD_DISTRIBUTION_HELP_SUCCESS });
            dispatch({ type: FETCH_DASHBOARD_DISTRIBUTION_HELP_DATA, payload: [] });
        }
    }
};

export const fetchIssuesData = (issuesFilter) => {
    if (issuesFilter['unitId'] === 0){issuesFilter['unitId'] = null;}
    if (issuesFilter['taskId'] === 0){issuesFilter['taskId'] = null;}
    if (issuesFilter['issueId'] === 0){issuesFilter['issueId'] = null;}
    if (issuesFilter['officerId'] === 0){issuesFilter['officerId'] = null;}

    console.log(issuesFilter); 

    return (dispatch) => {
        dispatch({ type: FETCH_DASHBOARD_ISSUES_START });
            axios.post('/getissuesdata', issuesFilter)
            .then(({ data }) => {
                if (data.result) {
                    dispatch({ type: FETCH_DASHBOARD_ISSUES_SUCCESS });
                    dispatch({ type: FETCH_DASHBOARD_ISSUES_DATA, payload: data.result.issuesData });
                } else {
                    console.log("payload: data.error", data.error);
                    dispatch({ type: FETCH_DASHBOARD_ISSUES_FAILED, payload: "Network Error" });
                }
            }).catch(function (error) {
                if (error.response.status === 401) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    dispatch({type: SIGNOUT_USER_SUCCESS});
                } else {
                    dispatch({ type: ERROR_OTHERS, payload: error });
                }                  
                dispatch({ type: FETCH_DASHBOARD_ISSUES_FAILED, payload: error.message });
                console.log("Error****:", error.message);
            });
    }
};

export const fetchDistributionIssuesData = (issuesFilter) => {
    if (issuesFilter['unitId'] === 0){issuesFilter['unitId'] = null;}
    if (issuesFilter['taskId'] === 0){issuesFilter['taskId'] = null;}
    if (issuesFilter['issueId'] === 0){issuesFilter['issueId'] = null;}
    if (issuesFilter['officerId'] === 0){issuesFilter['officerId'] = null;}

    return (dispatch) => {
        dispatch({ type: FETCH_DASHBOARD_DISTRIBUTION_ISSUES_START });
            axios.post('/getdistributionissuesdata', issuesFilter)
            .then(({ data }) => {
                if (data.result) {
                    dispatch({ type: FETCH_DASHBOARD_DISTRIBUTION_ISSUES_SUCCESS });
                    dispatch({ type: FETCH_DASHBOARD_DISTRIBUTION_ISSUES_DATA, payload: data.result.issuesDistributionData });
                } else {
                    console.log("payload: data.error", data.error);
                    dispatch({ type: FETCH_DASHBOARD_DISTRIBUTION_ISSUES_FAILED, payload: "Network Error" });
                }
            }).catch(function (error) {
                if (error.response.status === 401) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    dispatch({type: SIGNOUT_USER_SUCCESS});
                } else {
                    dispatch({ type: ERROR_OTHERS, payload: error });
                }                  
                dispatch({ type: FETCH_DASHBOARD_DISTRIBUTION_ISSUES_FAILED, payload: error.message });
                console.log("Error****:", error.message);
            });
    }
};

export const fetchIssuesRealtimeData = (issuesFilter) => {
    if (issuesFilter['unitId'] === 0){issuesFilter['unitId'] = null;}
    if (issuesFilter['taskId'] === 0){issuesFilter['taskId'] = null;}
    if (issuesFilter['issueId'] === 0){issuesFilter['issueId'] = null;}
    if (issuesFilter['officerId'] === 0){issuesFilter['officerId'] = null;}
    return (dispatch) => {
        dispatch({ type: FETCH_DASHBOARD_ISSUES_START });
            axios.post('/getissuesrealtimedata', issuesFilter)
            .then(({ data }) => {
                if (data.result) {
                    dispatch({ type: FETCH_DASHBOARD_ISSUES_SUCCESS });
                    dispatch({ type: FETCH_DASHBOARD_ISSUES_REAL_TIME_DATA, payload: data.result.issuesData });
                } else {
                    console.log("payload: data.error", data.error);
                    dispatch({ type: FETCH_DASHBOARD_ISSUES_FAILED, payload: "Network Error" });
                }
            }).catch(function (error) {
                if (error.response.status === 401) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    dispatch({type: SIGNOUT_USER_SUCCESS});
                } else {
                    dispatch({ type: ERROR_OTHERS, payload: error });
                }                  
                dispatch({ type: FETCH_DASHBOARD_ISSUES_FAILED, payload: error.message });
                console.log("Error****:", error.message);
            });
    }
};

export const fetchCoachingsData = (coachingsFilter) => {
    if (coachingsFilter['unitId'] === 0){coachingsFilter['unitId'] = null;}
    if (coachingsFilter['taskId'] === 0){coachingsFilter['taskId'] = null;}
    if (coachingsFilter['coachingId'] === 0){coachingsFilter['coachingId'] = null;}
    if (coachingsFilter['officerId'] === 0){coachingsFilter['officerId'] = null;}

    return (dispatch) => {
        dispatch({ type: FETCH_DASHBOARD_COACHINGS_START });
            axios.post('/getcoachingsdata', coachingsFilter)
            .then(({ data }) => {
                if (data.result) {
                    dispatch({ type: FETCH_DASHBOARD_COACHINGS_SUCCESS });
                    dispatch({ type: FETCH_DASHBOARD_COACHINGS_DATA, payload: data.result.coachingsData });
                } else {
                    console.log("payload: data.error", data.error);
                    dispatch({ type: FETCH_DASHBOARD_COACHINGS_FAILED, payload: "Network Error" });
                }
            }).catch(function (error) {
                if (error.response.status === 401) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    dispatch({type: SIGNOUT_USER_SUCCESS});
                } else {
                    dispatch({ type: ERROR_OTHERS, payload: error });
                }                  
                dispatch({ type: FETCH_DASHBOARD_COACHINGS_FAILED, payload: error.message });
                console.log("Error****:", error.message);
            });
    }
};

export const fetchDistributionCoachingsData = (coachingsFilter) => {
    if (coachingsFilter['unitId'] === 0){coachingsFilter['unitId'] = null;}
    if (coachingsFilter['coachingId'] === 0){coachingsFilter['coachingId'] = null;}
    if (coachingsFilter['officerId'] === 0){coachingsFilter['officerId'] = null;}

    return (dispatch) => {
        dispatch({ type: FETCH_DASHBOARD_DISTRIBUTION_COACHINGS_START });
            axios.post('/getdistributioncoachingsdata', coachingsFilter)
            .then(({ data }) => {
                if (data.result) {
                    dispatch({ type: FETCH_DASHBOARD_DISTRIBUTION_COACHINGS_SUCCESS });
                    dispatch({ type: FETCH_DASHBOARD_DISTRIBUTION_COACHINGS_DATA, payload: data.result.coachingsDistributionData });
                } else {
                    console.log("payload: data.error", data.error);
                    dispatch({ type: FETCH_DASHBOARD_DISTRIBUTION_COACHINGS_FAILED, payload: "Network Error" });
                }
            }).catch(function (error) {
                if (error.response.status === 401) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    dispatch({type: SIGNOUT_USER_SUCCESS});
                } else {
                    dispatch({ type: ERROR_OTHERS, payload: error });
                }                  
                dispatch({ type: FETCH_DASHBOARD_DISTRIBUTION_COACHINGS_FAILED, payload: error.message });
                console.log("Error****:", error.message);
            });
    }
};

export const fetchCoachingsRealtimeData = (coachingsFilter) => {
    if (coachingsFilter['unitId'] === 0){coachingsFilter['unitId'] = null;}
    if (coachingsFilter['taskId'] === 0){coachingsFilter['taskId'] = null;}
    if (coachingsFilter['coachingId'] === 0){coachingsFilter['coachingId'] = null;}
    if (coachingsFilter['officerId'] === 0){coachingsFilter['officerId'] = null;}
    return (dispatch) => {
        dispatch({ type: FETCH_DASHBOARD_COACHINGS_START });
            axios.post('/getcoachingsrealtimedata', coachingsFilter)
            .then(({ data }) => {
                if (data.result) {
                    dispatch({ type: FETCH_DASHBOARD_COACHINGS_SUCCESS });
                    dispatch({ type: FETCH_DASHBOARD_COACHINGS_REAL_TIME_DATA, payload: data.result.coachingsData });
                } else {
                    console.log("payload: data.error", data.error);
                    dispatch({ type: FETCH_DASHBOARD_COACHINGS_FAILED, payload: "Network Error" });
                }
            }).catch(function (error) {
                if (error.response.status === 401) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    dispatch({type: SIGNOUT_USER_SUCCESS});
                } else {
                    dispatch({ type: ERROR_OTHERS, payload: error });
                }                  
                dispatch({ type: FETCH_DASHBOARD_COACHINGS_FAILED, payload: error.message });
                console.log("Error****:", error.message);
            });
    }
};

export const fetchTasksRealtimeData = (tasksFilter) => {
    if (tasksFilter['unitId'] === 0){tasksFilter['unitId'] = null;}
    if (tasksFilter['taskId'] === 0){tasksFilter['taskId'] = null;}
    if (tasksFilter['officerId'] === 0){tasksFilter['officerId'] = null;}
    return (dispatch) => {
        dispatch({ type: FETCH_DASHBOARD_TASKS_START });
            axios.post('/gettasksrealtimedata', tasksFilter)
            .then(({ data }) => {
                if (data.result) {
                    dispatch({ type: FETCH_DASHBOARD_TASKS_SUCCESS });
                    dispatch({ type: FETCH_DASHBOARD_TASKS_REAL_TIME_DATA, payload: data.result.tasksData });
                } else {
                    console.log("payload: data.error", data.error);
                    dispatch({ type: FETCH_DASHBOARD_TASKS_FAILED, payload: "Network Error" });
                }
            }).catch(function (error) {
                if (error.response.status === 401) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    dispatch({type: SIGNOUT_USER_SUCCESS});
                } else {
                    dispatch({ type: ERROR_OTHERS, payload: error });
                }                  
                dispatch({ type: FETCH_DASHBOARD_COACHINGS_FAILED, payload: error.message });
                console.log("Error****:", error.message);
            });
    }
};

//-----------------------------Filters-----------------------------//

export const setDashboardFiltersSelectedCompany = (dashboardFiltersSelectedCompany) => {
    return (dispatch) => {
        dispatch({ type: DASHBOARD_FILTERS_SELECTEDCOMPANY, payload: dashboardFiltersSelectedCompany });
    }
}

export const clearDashboardFiltersSelectedCompany = () => {
    return (dispatch) => {
        dispatch({ type: DASHBOARD_FILTERS_CLEAR_SELECTEDCOMPANY });
    }
}

export const setDashboardFiltersSelectedUnit = (dashboardFiltersSelectedUnit) => {
    return(dispatch) => {
        dispatch({ type: DASHBOARD_FILTERS_SELECTEDUNIT, payload: dashboardFiltersSelectedUnit });
    }
}

export const setDashboardFiltersSelectedOfficer = (dashboardFiltersSelectedOfficer) => {
    return (dispatch) => {
        dispatch({ type: DASHBOARD_FILTERS_SELECTEDOFFICER, payload: dashboardFiltersSelectedOfficer });
    }
}

export const setDashboardFiltersSelectedTask = (dashboardFiltersSelectedTask) => {
    return (dispatch) => {
        dispatch({ type: DASHBOARD_FILTERS_SELECTEDTASK, payload: dashboardFiltersSelectedTask });
    }
}

export const setDashboardFiltersSelectedDateFrom = (dashboardFiltersSelectedDateFrom) => {
    return (dispatch) => {
        dispatch({ type: DASHBOARD_FILTERS_SELECTEDDATEFROM, payload: dashboardFiltersSelectedDateFrom });
    }
}

export const setDashboardFiltersSelectedDateTo = (dashboardFiltersSelectedDateTo) => {
    return (dispatch) => {
        dispatch({ type: DASHBOARD_FILTERS_SELECTEDDATETO, payload: dashboardFiltersSelectedDateTo });
    }
}

export const setDashboardFiltersShowValue = (dashboardFiltersShowValue) => {
    return (dispatch) => {
        dispatch({ type: DASHBOARD_FILTERS_SHOWVALUE, payload: dashboardFiltersShowValue });
    }
}

export const setDashboardFiltersSelectedViewType = (dashboardFiltersSelectedViewType) => {
    return (dispatch) => {
        dispatch({ type: DASHBOARD_FILTERS_SELECTEDVIEWTYPE, payload: dashboardFiltersSelectedViewType });
    }
}

export const setDashboardFiltersSelectedIssue = (dashboardFiltersSelectedIssue) => {
    return (dispatch) => {
        dispatch({ type: DASHBOARD_FILTERS_SELECTEDISSUE, payload: dashboardFiltersSelectedIssue });
    }
}

export const setDashboardFiltersSelectedCoaching = (dashboardFiltersSelectedCoaching) => {
    return (dispatch) => {
        dispatch({ type: DASHBOARD_FILTERS_SELECTEDCOACHING, payload: dashboardFiltersSelectedCoaching });
    }
}

export const setDashboardFilterSelectedLimitType = (dashboardFiltersSelectedLimitType) => {
    return (dispatch) => {
        dispatch({ type: DASHBOARD_FILTERS_SELECTEDLIMITTYPE, payload: dashboardFiltersSelectedLimitType });
    }
}

export const setDashboardFilterSelectedShowAndon = (dashboardFiltersShowAndon) => {
    return (dispatch) => {
        dispatch({ type: DASHBOARD_FILTERS_SHOW_ANDON, payload: dashboardFiltersShowAndon });
    }
}



export const fetchTasksLogsTableData = (tableDataFilter) => {
    return (dispatch) => {
        dispatch({ type: FETCH_DASHBOARD_TASKSLOGS_TABLEDATA_START });
        axios.post('/taskslogs', tableDataFilter)
        .then(({ data }) => {
            if (data.result) {
                dispatch({ type: FETCH_DASHBOARD_TASKSLOGS_TABLEDATA_SUCCESS });
                dispatch({ type: FETCH_DASHBOARD_TASKSLOGS_TABLEDATA_DATA, payload: data.result.tableData });
            } else {
                console.log("payload: data.error", data.error);
                dispatch({ type: FETCH_DASHBOARD_TASKSLOGS_TABLEDATA_FAILED, payload: "Network Error" });
            }
        }).catch(function (error) {
            if (error.response.status === 401) {
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                dispatch({type: SIGNOUT_USER_SUCCESS});
            } else {
                dispatch({ type: ERROR_OTHERS, payload: error });
            }                  
            dispatch({ type: FETCH_DASHBOARD_TASKSLOGS_TABLEDATA_FAILED, payload: error.message });
            console.log("Error****:", error.message);
        });
    }
};

export const filterLogsTableData = (data, filterText) => {
    return (dispatch) => {
        var filteredData = data.filter(function(text) {
                if (filterText.length > 0) {
                    return (
                            (text.unit === null ? false : (text.unit.toLowerCase().indexOf(filterText.toLowerCase()) >= 0)) ||
                            (text.officer === null ? false : (text.officer.toLowerCase().indexOf(filterText.toLowerCase()) >= 0)) ||
                            (text.task === null ? false : (text.task.toLowerCase().indexOf(filterText.toLowerCase()) >= 0))
                    );
                } else {
                    return true;
                }
        });
        dispatch({ type: FETCH_DASHBOARD_TASKSLOGS_TABLEDATA_FILTEREDDATA, payload: filteredData });
    }
};

//-----------------------------Filters-----------------------------//
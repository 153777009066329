import {INIT_URL, SIGNOUT_USER_SUCCESS, USER_DATA, USER_TOKEN_SET, SESSION_TIMED_OUT, AUTHENTICATE_REDIRECT, AUTHENTICATE_REDIRECTED} from "../constants/ActionTypes";

const INIT_STATE = {
  token: JSON.parse(localStorage.getItem('token')),
  authUser: JSON.parse(localStorage.getItem('user')),
  initURL: '',
  isSessionTimedOut: false,
  isValidToken: false,
  redirectfromauth: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {


    case INIT_URL: {
      return {...state, initURL: action.payload};
    }

    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        token: null,
        authUser: null,
        initURL: '',
        isSessionTimedOut: true,
        isValidToken: false
      }
    }

    case USER_DATA: {
      return {
        ...state,
        authUser: action.payload,
      };
    }

    case USER_TOKEN_SET: {
      return {
        ...state,
        token: action.payload,
        isSessionTimedOut: false,
        isValidToken: true
      };
    }

    case AUTHENTICATE_REDIRECT: {
      return {
        ...state,        
        isSessionTimedOut: false,
        isValidToken: true,        
        redirectfromauth: true
      };
    }

    case AUTHENTICATE_REDIRECTED: {
      return {
        ...state,
        redirectfromauth: false
      };
    }

    case SESSION_TIMED_OUT: {
      return {
        ...state,
        isSessionTimedOut: action.payload
      };      
    }

    default:
      return state;
  }
}

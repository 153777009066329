import {     
    FETCH_ALL_COACHINGS_START,
    COACHINGS_DATA, 
    FILTERED_COACHINGS_DATA, 
    UPDATE_COACHING_START, 
    UPDATE_COACHING_SUCCESS, 
    DELETE_COACHING_START, 
    DELETE_COACHING_SUCCESS, 
    SELECT_COACHING
  } from "../constants/ActionTypes";
  
  const INIT_STATE = {
    coachings: [],
    filteredCoachings: [],
    updatedCoachingId: 0,
    updatingCoaching: false,
    coachingsLoaded: false
  };
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
  
      case FETCH_ALL_COACHINGS_START: {
        return {
          ...state,
          ['coachingsLoaded' + action.unitId]: false
        };        
      }
  
      case COACHINGS_DATA: {
        return {
          ...state,
          coachings: action.payload === undefined ? [] : action.payload,
          ['coachings' + action.unitId]: action.payload === undefined ? [] : action.payload,
          ['activeCoachings' + action.unitId]: action.payload === undefined ? [] : action.payload.filter(n => n.isActive),
          filteredCoachings: action.payload === undefined ? [] : action.payload,
          ['coachingsLoaded' + action.unitId]: true
        };
      }
  
      case FILTERED_COACHINGS_DATA: {
        return {
          ...state,
          filteredCoachings: action.payload === undefined ? [] : action.payload,
        };
      }      
  
      case UPDATE_COACHING_START: {
        return {
          ...state,
          updateSuccess: false,
          updatedCoachingId: action.payload,
          updatingCoaching: true,
        };
      }
      
      case UPDATE_COACHING_SUCCESS: {
        return {
          ...state,
          updateSuccess: true,
          updatedCoachingId: action.payload,
          updatingCoaching: false
        };
      }  
      
      case DELETE_COACHING_START: {
        return {
          ...state,
          updatingCoaching: true
        };
      }
  
      case DELETE_COACHING_SUCCESS: {
        return {
          ...state,
          updatingCoaching: false
        };
      }    
  
      case SELECT_COACHING: {
        return {
          ...state,
          selectedCoaching: action.payload
        };      
      }
  
      default:
        return state;
    }
  }
  
import {ERROR_OTHERS, CLEAN_ERROR} from "../constants/ActionTypes";

const INIT_STATE = {
    error: undefined,
    hasError: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

      case ERROR_OTHERS: {
        return {
          ...state,
          error: action.message,
          hasError: true
        };
      }

      case CLEAN_ERROR: {
        return {
          ...state,
          error: undefined,
          hasError: false
        };
      }

      default:
        return state;
    }
  }
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import asyncComponent from "../../../util/asyncComponent";

const Structure = ({ match }) => (
    <div className="app-wrapper">
        <Switch>
            <Redirect exact from={`${match.url}/`} to={`${match.url}/units`} />
            <Route exact path={`${match.url}/companies`} component={asyncComponent(() => import('./routes/Companies'))} />
            <Route exact path={`${match.url}/units`} component={asyncComponent(() => import('./routes/Units'))} />
            <Route exact path={`${match.url}/units/edit`} component={asyncComponent(() => import('./routes/Units/Edit'))} />
            <Route exact path={`${match.url}/users`} component={asyncComponent(() => import('./routes/Users'))} />
            <Route exact path={`${match.url}/users/edit`} component={asyncComponent(() => import('./routes/Users/Edit'))} />            
            <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
        </Switch>
    </div>
);

export default Structure;
import React from 'react';
import CustomScrollbars from 'util/CustomScrollbars';
import Navigation from "../../components/Navigation";

const SideBarManager = () => {
    const navigationMenus = [
        {
            name: 'sidebar.main',
            type: 'section',
            children: [
                {
                    name: 'sidebar.settings',
                    icon: 'settings',
                    type: 'collapse',
                    children: [
                        {
                            name: 'pages.units',
                            type: 'item',
                            link: '/app/settings/units',
                        },
                        {
                            name: 'pages.attendances',
                            type: 'item',
                            link: '/app/settings/attendances',
                        }                          
                    ]
                },
                {
                    name: 'sidebar.dashboard',
                    icon: 'chart',
                    type: 'collapse',
                    children: [
                        {
                            name: 'pages.teamefficiency',
                            type: 'collapse',
                            children: [
                                {
                                    name: 'pages.efficiencyglobal',
                                    type: 'item',
                                    link: '/app/dashboard/teamefficiency',    
                                },
                                {
                                    name: 'pages.efficiencyofficers',
                                    type: 'item',
                                    link: '/app/dashboard/teamefficiencyofficers',    
                                },                                  
                                {
                                    name: 'pages.taskdetails',
                                    type: 'item',                            
                                    link: '/app/dashboard/taskdetails',
                                },
                                {
                                    name: 'pages.performance',
                                    type: 'item',                            
                                    link: '/app/dashboard/performanceaverage',
                                }
                            ]
                        },
                        {
                            name: 'pages.distribution',
                            type: 'collapse',
                            children: [
                                {
                                    name: 'pages.tasks',
                                    type: 'item',                            
                                    link: '/app/dashboard/distribution',
                                },
                                {
                                    name: 'pages.issues',
                                    type: 'collapse',
                                    children: [
                                        {
                                            name: 'pages.issuesdistributionbytask',
                                            type: 'item',
                                            link: '/app/dashboard/issuesdistribution/distribution/bytask'
                                        },
                                        {
                                            name: 'pages.issuesdistributionbyissue',
                                            type: 'item',
                                            link: '/app/dashboard/issuesdistribution/distribution/byissue'
                                        }
                                    ]                          
                                    
                                },
                                {
                                    name: 'pages.coaching',
                                    type: 'collapse',                            
                                    children: [
                                        {
                                            name: 'pages.coachingsdistributionbycoaching',
                                            type: 'item',
                                            link: '/app/dashboard/coachingsdistribution/distribution/bycoaching'
                                        },
                                        {
                                            name: 'pages.coachingsdistributionbyofficer',
                                            type: 'item',
                                            link: '/app/dashboard/coachingsdistribution/distribution/byofficer'
                                        }
                                    ]        
                                }
                            ]
                        },
                        {
                            name: 'pages.evolution',
                            type: 'collapse',                            
                            children: [
                                {
                                    name: 'pages.tasks',
                                    type: 'collapse',                            
                                    children: [
                                        {
                                            name: 'pages.timeline',
                                            type: 'item',                            
                                            link: '/app/dashboard/timeline',
                                        },
                                        {
                                            name: 'pages.volumetry',
                                            type: 'item',                            
                                            link: '/app/dashboard/volumetry',
                                        }   
                                    ]
                                },
                                {
                                    name: 'pages.issues',
                                    type: 'collapse',                            
                                    children: [
                                        {
                                            name: 'pages.timeline',
                                            type: 'item',                            
                                            link: '/app/dashboard/issues/timeline',
                                        },
                                        {
                                            name: 'pages.volumetry',
                                            type: 'item',                            
                                            link: '/app/dashboard/issues/volumetry',
                                        }   
                                    ]
                                },
                                {
                                    name: 'pages.coachings',
                                    type: 'collapse',                            
                                    children: [
                                        {
                                            name: 'pages.timeline',
                                            type: 'item',                            
                                            link: '/app/dashboard/coachings/timeline',
                                        },
                                        {
                                            name: 'pages.volumetry',
                                            type: 'item',                            
                                            link: '/app/dashboard/coachings/volumetry',
                                        }   
                                    ]
                                }                           
                            ],
                        },
                        // {
                        //     name: 'pages.realtime',
                        //     type: 'collapse',                            
                        //     children: [
                        //         {
                        //             name: 'pages.andon',
                        //             type: 'item',                            
                        //             link: '/app/dashboard/realtime/andon',
                        //         },
                        //         {
                        //             name: 'pages.issues',
                        //             type: 'item',                            
                        //             link: '/app/dashboard/realtime/issues',
                        //         },
                        //         {
                        //             name: 'pages.coachings',
                        //             type: 'item',                            
                        //             link: '/app/dashboard/realtime/coachings',
                        //         }

                        //     ],
                        // },
                        {
                            name: 'pages.listing',
                            type: 'collapse',                            
                            children: [
                                {
                                    name: 'pages.activitylist',
                                    type: 'item',
                                    link: '/app/dashboard/activitylist',
                                },
                                {
                                    name: 'pages.limits',
                                    type: 'item',                            
                                    link: '/app/dashboard/listing/limits',
                                },                                
                                {
                                    name: 'pages.issues',
                                    type: 'item',                            
                                    link: '/app/dashboard/listing/issues',
                                },
                                {
                                    name: 'pages.coachings',
                                    type: 'item',                            
                                    link: '/app/dashboard/listing/coachings',
                                },
                                {
                                    name: 'pages.activitylogs',
                                    type: 'item',
                                    link: '/app/dashboard/activitylogs',
                                }                          
                            ],
                        },                                                                                 
                        {
                            name: 'pages.standardization',
                            type: 'item',                            
                            link: '/app/dashboard/standardization',
                        },
                        {
                            name: 'pages.pivot',
                            type: 'item',                            
                            link: '/app/dashboard/pivottable',
                        }
                    ]
                }                
            ]
        },
    ];

    return (
        <CustomScrollbars className=" scrollbar">
            <Navigation menuItems={navigationMenus} />
        </CustomScrollbars>
    );
};

export default SideBarManager;

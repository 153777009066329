export default {
  palette: {
    primary: {
      light: '#844f9b',
      main: '#662482',
      dark: '#47195b',
      contrastText: '#fff'
    },
    secondary: {
      light: '#ea3397',
      main: '#E5007E',
      dark: '#a00058',
      contrastText: '#fff'
    }
  },
  status: {
    danger: 'orange',
  },
  typography: {
    button: {
      fontWeight: 400,
      textAlign: 'capitalize'
    },
  },
};

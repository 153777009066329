import {
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
    COMPANIES_DATA,
    SIGNOUT_USER_SUCCESS,
    ERROR_OTHERS
} from "../constants/ActionTypes";
import axios from 'util/Api';


export const fetchCompanies = (defaultCompanyId) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.get('/companies', {
            params: {
                defaultCompanyId: defaultCompanyId
            }
        })
            .then(({ data }) => {
                if (data.result) {                    
                    dispatch({ type: FETCH_SUCCESS });
                    dispatch({ type: COMPANIES_DATA, payload: data.result.companies });
                } else {
                    console.log("payload: data.error", data.error);
                    dispatch({ type: FETCH_ERROR, payload: "Network Error" });
                }
            }).catch(function (error) {
                if (error.response.status === 401) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    dispatch({type: SIGNOUT_USER_SUCCESS});
                } else {
                    dispatch({ type: ERROR_OTHERS, payload: error });
                }                
                dispatch({ type: FETCH_ERROR, payload: error.message });
                console.log("Error****:", error.message);
            });
    }
};

export const sortData = (data, order, orderBy) => {
    return (dispatch) => {
        order === "desc"
            ? data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
            : data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));
        dispatch({ type: COMPANIES_DATA, payload: data });
    }
};
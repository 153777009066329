import React from 'react';

import CompanyItem from './CompanyItem';
import CustomScrollbars from 'util/CustomScrollbars';

const CompanySwitcher = ({ switchCompany, handleRequestClose, data}) => {

    return (      
        <CustomScrollbars className="messages-list language-list scrollbar" style={{ height: 230 }}>
            <ul className="list-unstyled">
                {data.map((name, index) => <CompanyItem key={index} company={name}
                    handleRequestClose={handleRequestClose}
                    switchCompany={switchCompany} />)}
            </ul>
        </CustomScrollbars>
    )
};

export default CompanySwitcher;


import { 
    FETCH_ATTENDANCES_DATA,
    FETCH_ATTENDANCES_FILTEREDDATA,
    SELECT_ATTENDANCE,
    ATTENDANCE_RECORD,
    UPDATE_ATTENDANCE_START,
    DELETE_ATTENDANCE_START,
    DELETE_ATTENDANCE_SUCCESS
} from "../constants/ActionTypes";

const INIT_STATE = {
  attendances: [],
  filteredAttendances: [],
  attendanceCalendarId: 0,
  attendancesLoaded: false,
  updatingAttendanceCalendar: false,
  selectedAttendanceCalendar: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case FETCH_ATTENDANCES_DATA: {
      return {
        ...state,
        attendances: action.payload === undefined ? [] : action.payload,
        filteredAttendances: action.payload === undefined ? [] : action.payload,
        attendancesLoaded: true
      };
    }

    case ATTENDANCE_RECORD: {
      return {
        ...state,
        attendanceCalendarId: action.payload.attendanceCalendarId,
        updatingAttendanceCalendar: false
      };
    }

    case DELETE_ATTENDANCE_SUCCESS: {
      return {
        ...state,
        attendanceCalendarId: 0,
        updatingAttendanceCalendar: false
      };
    }

    case UPDATE_ATTENDANCE_START:
    case DELETE_ATTENDANCE_START: {
      return {
        ...state,
        updatingAttendanceCalendar: true
      };
    }

    case SELECT_ATTENDANCE: {
      return {
        ...state,
        selectedAttendanceCalendar: action.payload
      };
    }

    case FETCH_ATTENDANCES_FILTEREDDATA: {
      return {
        ...state,
        filteredAttendances: action.payload === undefined ? [] : action.payload,
      };
    }      

    default:
      return state;
  }
}

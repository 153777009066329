import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET,
  AUTHENTICATE_REDIRECT,
  AUTHENTICATE_REDIRECTED,
  CLEAN_ERROR,
  SESSION_TIMED_OUT,
  ERROR_OTHERS,
  SIGNIN_USER_ERROR
} from "../constants/ActionTypes";
import axios from 'util/Api';
import { toast } from 'react-toastify';

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export const setIsRedirected =() => {
  return { type: AUTHENTICATE_REDIRECTED };
}

export const userSignUp = ({name, email, password}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('auth/register', {
        email: email,
        password: password,
        name: name
      }
    ).then(({data}) => {
      if (data) {
        localStorage.setItem("token", JSON.stringify(data.token.access_token));
        localStorage.setItem("user", JSON.stringify(data.user));
        axios.defaults.headers.common['Authorization'] = "Bearer " + data.token.access_token;
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: USER_TOKEN_SET, payload: data.token.access_token});
        dispatch({type: USER_DATA, payload: data.user});
      } else {
        console.log("payload: data.error", data.error);
        dispatch({type: FETCH_ERROR, payload: "Network Error"});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const userSignIn = ({email, password}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('login', {
        email: email,
        password: password,
      }
    ).then(({data}) => {
      console.log("data in userSignIn",data);
      if (data) {
        if (data.error.errorId === 0) {
          localStorage.setItem("token", JSON.stringify(data.token.access_token));
          localStorage.setItem("user", JSON.stringify(data.user));
          axios.defaults.headers.common['Authorization'] = "Bearer " + data.token.access_token;
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: USER_DATA, payload: data.user});
          dispatch({type: USER_TOKEN_SET, payload: data.token.access_token});
          dispatch({type: SESSION_TIMED_OUT, payload: false});
          dispatch({type: CLEAN_ERROR});
        } else {
          dispatch({type: SIGNIN_USER_ERROR, payload: data.error.errorMessage});
          toast.error(data.error.errorMessage, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            });           
        }
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const isTokenValid = (token) => {  
  return (dispatch) => {
      dispatch({ type: FETCH_START });
      
      axios.get('/isTokenValid',{
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then(({ data }) => {
              if (data) {
                  dispatch({ type: FETCH_SUCCESS });
                  dispatch({type: USER_TOKEN_SET, payload: token});
              } else {
                  console.log("payload: data.error", "Token validation error");
                  dispatch({ type: FETCH_ERROR, payload: "Network Error" });
              }
          }).catch(function (error) {
              if (error.response.status === 401) {
                  localStorage.removeItem("token");
                  localStorage.removeItem("user");
                  dispatch({type: SIGNOUT_USER_SUCCESS});
              } else {
                  dispatch({ type: ERROR_OTHERS, payload: error });
              }                
              dispatch({ type: FETCH_ERROR, payload: error.message });
              console.log("Error****:", error.message);
          });
  }
};

export const isAuthenticateTokenValid = (token) => {  
  return (dispatch) => {
      dispatch({ type: FETCH_START });
      axios.get('/isTokenValid',{
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then(({ data }) => {
              if (data) {
                  localStorage.setItem("token", JSON.stringify(data.token.access_token));
                  localStorage.setItem("user", JSON.stringify(data.user));
                  axios.defaults.headers.common['Authorization'] = "Bearer " + data.token.access_token;
                  dispatch({type: FETCH_SUCCESS});
                  dispatch({type: USER_DATA, payload: data.user});
                  dispatch({type: USER_TOKEN_SET, payload: data.token.access_token});
                  dispatch({type: SESSION_TIMED_OUT, payload: false});
                  dispatch({type: CLEAN_ERROR});
                  dispatch({ type: AUTHENTICATE_REDIRECT });
              } else {
                  console.log("payload: data.error", "Token validation error");
                  dispatch({ type: FETCH_ERROR, payload: "Network Error" });
              }
          }).catch(function (error) {
              if (error.response.status === 401 || error.response.status === 422) {
                  localStorage.removeItem("token");
                  localStorage.removeItem("user");
                  dispatch({type: SIGNOUT_USER_SUCCESS});
              } else {
                  dispatch({ type: ERROR_OTHERS, payload: error });
              }                
              dispatch({ type: FETCH_ERROR, payload: error.message });
              console.log("Error****:", error.message);
          });
  }
};

export const userSignOut = () => {
  return (dispatch) => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    dispatch({type: SIGNOUT_USER_SUCCESS});    
  }
};
